/** @jsxImportSource @emotion/react */
/* eslint-disable */
import { useMutation, useQuery } from "@tanstack/react-query";
import Table, { ColumnsType } from "antd/es/table";
import { AnnouncementApi } from "api/announcement";
import { MemberMessageResponseType } from "api/announcement/type";
import { ReactComponent as ChevronDownIcon } from "assets/common/icons/chevron-down.svg";
import Loading from "components/common/Loading";
import { QueryKeys } from "constants/queryKeys";
import _map from "lodash/map";
import { useState } from "react";
import { Translation } from "react-i18next";
import { MemberMessageType } from "types/announcement";

import { message } from "antd";
import EmptyComponent from "components/common/EmptyComponent";
import { READ_VALUE } from "types/promotions";
import PersonalMessageHeader from "./PersonalMessageHeader";
import { PersonalMessagePageStyle } from "./index.style";
interface DataType extends MemberMessageType {
  key: React.Key;
  create_date: any;
  title: any;
  content: any;
}

const columns: ColumnsType<DataType> = [
  {
    title: (
      <Translation ns={["common"]}>
        {(t) => <>{t("time", { ns: "common" })}</>}
      </Translation>
    ),
    dataIndex: "create_date",
    key: "create_date",
    width: 200,
  },
  {
    title: (
      <Translation ns={["common"]}>
        {(t) => <>{t("title", { ns: "common" })}</>}
      </Translation>
    ),
    dataIndex: "title",
    key: "title",
  },
];

const PersonalMessagePage = () => {
  const [rowActive, setRowActive] = useState<number | undefined>(undefined);
  const { data: username } = useQuery<string>([QueryKeys.username]);
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery<MemberMessageResponseType>([QueryKeys.MemberMessage], {
    queryFn: async () => {
      return await AnnouncementApi.getMemberMessage({
        username: username as string,
      });
    },
    staleTime: Infinity,
  });
  const { mutate } = useMutation({
    mutationKey: ["ReadPlayerMessage"],
    mutationFn: AnnouncementApi.readPlayerMessage,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      message.error(error as string);
    },
  });
  const data = response?.data || [];
  const dataTable: DataType[] = _map(data, (item, index) => {
    return {
      ...item,
      key: index + 1,
      title: (
        <div css={PersonalMessagePageStyle.headTitleWrapper}>
          <div css={PersonalMessagePageStyle.headTitle}>{item.title}</div>
          <ChevronDownIcon
            css={PersonalMessagePageStyle.downIcon(rowActive === index + 1)}
          />
        </div>
      ),
    };
  });

  const handleReadMessage = (id: string) => {
    mutate({
      username: username as string,
      message_id: id,
    });
  };

  return (
    <div css={PersonalMessagePageStyle.self}>
      <PersonalMessageHeader />
      <div css={PersonalMessagePageStyle.content}>
        <div css={PersonalMessagePageStyle.tableWrapper}>
          {isLoading ? (
            <Loading center height={400} />
          ) : (
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <div
                    css={PersonalMessagePageStyle.expandContent}
                    dangerouslySetInnerHTML={{
                      __html: record.content,
                    }}
                  ></div>
                ),
                showExpandColumn: false,
                expandRowByClick: true,
                expandedRowKeys: rowActive ? [rowActive] : [],
                onExpand: (record, event) => {
                  setRowActive(record ? Number(event.key) : undefined);
                  if (event.is_read === READ_VALUE.noRead) {
                    handleReadMessage(event.id);
                  }
                },
                expandedRowClassName: (record, index, indent) => {
                  let classRow = "";
                  if (record.key === rowActive) {
                    classRow += " active";
                  }
                  return classRow;
                },
              }}
              dataSource={[...dataTable]}
              css={PersonalMessagePageStyle.table}
              pagination={false}
              rowClassName={(record) => {
                return record.is_read === READ_VALUE.noRead ? "no-reading" : "";
              }}
              bordered
              locale={{
                emptyText: (
                  <div css={PersonalMessagePageStyle.empty}>
                    <EmptyComponent />
                  </div>
                ),
              }}
              scroll={{
                y: "calc(100vh - 160px)",
                scrollToFirstRowOnChange: true,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalMessagePage;
