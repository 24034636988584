import { PATH } from "./path";

interface UserMenuItem {
  name: string;
  path: string;
}

export const MENU_USER_LAYOUT: UserMenuItem[] = [
  {
    name: "common:documentation",
    path: PATH.User.Profile,
  },
  // {
  //   name: "common:transferFunds",
  //   path: PATH.User.PlatformTransfer,
  // },
  {
    name: "payment:deposit.self",
    path: PATH.User.Deposit,
  },
  {
    name: "payment:withdrawal.self",
    path: PATH.User.Withdraw,
  },
  {
    name: "transaction:self",
    path: PATH.User.Transaction,
  },
  {
    name: "common:promotion",
    path: PATH.User.MemberPromotion,
  },
];
