import loadable from "@loadable/component";
import App from "App";
import { PATH, userPath } from "constants/path";
import { DepositContextProvider } from "contexts/deposit/deposit.context";
import BlankLayout from "layouts/desktop/BlankLayout";
import { DefaultLayout } from "layouts/desktop/DefaultLayout";
import ModalLayout from "layouts/desktop/ModalLayout";
import { UserLayout } from "layouts/desktop/UserLayout";
import ReferCode from "pages/common/ReferCode";
import AnnouncementPage from "pages/desktop/AnnouncementPage";
import DepositExample from "pages/desktop/DepositExample";
import PersonalMessagePage from "pages/desktop/PersonalMessagePage";
import { RouteObject } from "react-router-dom";

import ProtectedRoute from "./protected";

const HomePage = loadable(() => import("pages/desktop/HomePage"));
const SubGamePage = loadable(() => import("pages/desktop/SubGamePage"));
const Promotion = loadable(() => import("pages/desktop/Promotion"));
const AboutUS = loadable(() => import("pages/desktop/AboutUS"));
const Introduction = loadable(
  () => import("pages/desktop/AboutUS/Introduction"),
);
const Spirit = loadable(() => import("pages/desktop/AboutUS/Spirit"));
const HelpCenter = loadable(() => import("pages/desktop/HelpCenter"));
const HelpContact = loadable(
  () => import("pages/desktop/HelpCenter/HelpContact"),
);
const HelpAQ = loadable(() => import("pages/desktop/HelpCenter/HelpQA"));
const TeachHelp = loadable(() => import("pages/desktop/HelpCenter/TeachHelp"));
const TeachSystem = loadable(
  () => import("pages/desktop/HelpCenter/TeachSystem"),
);
const HelpRemote = loadable(
  () => import("pages/desktop/HelpCenter/HelpRemote"),
);

const ServiceCenter = loadable(
  () => import("pages/desktop/HelpCenter/ServiceCenter"),
);
const GameTerms = loadable(() => import("pages/desktop/GameTerms"));
const ServiceRule = loadable(
  () => import("pages/desktop/GameTerms/ServiceRule"),
);
const Privacy = loadable(() => import("pages/desktop/GameTerms/Privacy"));
const Responsible = loadable(
  () => import("pages/desktop/GameTerms/Responsible"),
);
const Safety = loadable(() => import("pages/desktop/GameTerms/Safety"));
const Statement = loadable(() => import("pages/desktop/GameTerms/Statement"));

const ProfilePage = loadable(() => import("pages/desktop/ProfilePage"));
const WithdrawPage = loadable(() => import("pages/desktop/WithdrawPage"));
const TransactionPage = loadable(() => import("pages/desktop/TransactionPage"));
const MemberPromotionPage = loadable(
  () => import("pages/desktop/MemberPromotionPage"),
);
const DepositPage = loadable(() => import("pages/desktop/DepositPage"));

export const DesktopRoutes: RouteObject[] = [
  {
    path: PATH.ReferCode,
    element: <ReferCode />,
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <ModalLayout />,
        children: [
          {
            path: PATH.promotion,
            element: <Promotion />,
          },
          {
            path: PATH.HelpCenter.ServiceCenter,
            element: <ServiceCenter />,
          },
          {
            path: PATH.SubGame + "/:provider",
            element: <SubGamePage />,
          },
          {
            path: PATH.SubGame,
            element: <SubGamePage />,
          },
          {
            path: PATH.HelpCenter.index,
            element: <HelpCenter />,
            children: [
              {
                path: PATH.HelpCenter.HelpContact,
                element: <HelpContact />,
              },
              {
                path: PATH.HelpCenter.HelpQA,
                element: <HelpAQ />,
              },
              {
                path: PATH.HelpCenter.TeachHelp,
                element: <TeachHelp />,
              },
              {
                path: PATH.HelpCenter.TeachSystem,
                element: <TeachSystem />,
              },
              {
                path: PATH.HelpCenter.HelpRemote,
                element: <HelpRemote />,
              },
            ],
          },
          {
            path: PATH.AboutUS.index,
            element: <AboutUS />,
            children: [
              {
                path: PATH.AboutUS.introduction,
                element: <Introduction />,
              },
              {
                path: PATH.AboutUS.spirit,
                element: <Spirit />,
              },
            ],
          },
          {
            path: PATH.GameTerms.index,
            element: <GameTerms />,
            children: [
              {
                path: PATH.GameTerms.ServiceRule,
                element: <ServiceRule />,
              },
              {
                path: PATH.GameTerms.Privacy,
                element: <Privacy />,
              },
              {
                path: PATH.GameTerms.Responsible,
                element: <Responsible />,
              },
              {
                path: PATH.GameTerms.Safety,
                element: <Safety />,
              },
              {
                path: PATH.GameTerms.Statement,
                element: <Statement />,
              },
            ],
          },
          {
            element: <DefaultLayout />,
            children: [
              {
                path: PATH.Home,
                element: <HomePage />,
              },
            ],
          },

          {
            element: <BlankLayout />,
            children: [
              {
                path: PATH.Annoucement,
                element: <AnnouncementPage />,
              },
              {
                path: PATH.PersonalMessage,
                element: <PersonalMessagePage />,
              },
            ],
          },

          {
            element: <ProtectedRoute />,
            children: [
              {
                path: userPath,
                element: <UserLayout />,
                children: [
                  {
                    path: PATH.User.Profile,
                    element: <ProfilePage />,
                  },
                  {
                    path: PATH.User.Withdraw,
                    element: <WithdrawPage />,
                  },
                  {
                    path: PATH.User.Transaction,
                    element: <TransactionPage />,
                  },
                  {
                    path: PATH.User.MemberPromotion,
                    element: <MemberPromotionPage />,
                  },
                  {
                    path: PATH.User.Deposit,
                    element: (
                      <DepositContextProvider>
                        <DepositPage />
                      </DepositContextProvider>
                    ),
                  },
                  {
                    path: PATH.User.DepositExampleDetail,
                    element: <DepositExample />,
                  },
                  {
                    path: "*",
                    element: <h1>404</h1>,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
