import { AppState, ContextActionType } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { Dispatch } from "react";
import { ContextAction } from "types";

export const handleCloseModal = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
) => {
  if (!dispatch) return;

  dispatch({
    type: "OPENMODAL",
    payload: {
      ...state,
      openModal: ModalTypeValue.CLOSE,
    },
  });
};

export const handleChangeLanguage = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
  language: string,
) => {
  if (!dispatch) return;

  dispatch({
    type: "OPENMODAL",
    payload: {
      ...state,
      language: language,
    },
  });
};

export const handleOpenModal = (
  state: AppState,
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null,
  openModal: ModalTypeValue,
  code?: string,
) => {
  if (!dispatch) return;
  // console.log(code);
  dispatch({
    type: "OPENMODAL",
    payload: {
      ...state,
      openModal,
      code,
    },
  });
};
