import { Modal } from "components/common/Modal";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";

function ModalLayout() {
  return (
    <Fragment>
      <Outlet />
      <Modal />
    </Fragment>
  );
}

export default ModalLayout;
