/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { Button, Collapse, CollapseProps, Radio, Spin, Tooltip } from "antd";
import { BankApi } from "api/bank";
import { ReactComponent as ChevronDownIcon } from "assets/desktop/icons/chevron-down.svg";
import IconAddBank from "assets/desktop/images/icon_add-bank.png";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { useWithdrawContext } from "contexts/withdraw/withdraw.context";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PlayerBankType } from "types/bank";

import { BanksCollapseStyle } from "./index.style";

const MAX_TOTAL_BANKS = 3;

const BanksCollapse = () => {
  const { t } = useTranslation(["payment", "common"]);
  const navigate = useNavigate();

  const { state: withdrawState, dispatch: withdrawDispatch } =
    useWithdrawContext();

  const [collapseActiveKey, setCollapseActiveKey] = useState<string[]>();

  const { data: username } = useQuery<string>([QueryKeys.username]);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetMemberBanks],
    queryFn: () => BankApi.getListPlayerBank(username as string),
    enabled: !!username,
    staleTime: Infinity,
  });

  const bankList = data?.slice(0, MAX_TOTAL_BANKS);

  const handleBankSelected = (value: PlayerBankType | null) => {
    withdrawDispatch &&
      withdrawDispatch({
        type: "PLAYER_BANK_SELECT",
        payload: {
          ...withdrawState,
          playerBankSelected: value,
        },
      });
  };

  const handleAddMemberBank = () => {
    navigate(PATH.User.AddMemberBank);
  };

  useEffect(() => {
    handleBankSelected(data?.[0] || null);
  }, [data]);

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div css={BanksCollapseStyle.collapseLabel}>
          <div css={BanksCollapseStyle.bankInfo()}>
            <span className="label">{t("bank")}:</span>
            <span className="value">
              {withdrawState.playerBankSelected?.bank_name}
            </span>
          </div>
          <div css={BanksCollapseStyle.bankInfo(!!collapseActiveKey?.length)}>
            <span className="label">{t("bankAccount.lastNumber")}:</span>
            <span className="value">
              {withdrawState.playerBankSelected?.bank_account_no.slice(-4)}
            </span>
            <ChevronDownIcon />
          </div>
        </div>
      ),
      children: (
        <div css={BanksCollapseStyle.collapseContent}>
          <Radio.Group
            value={withdrawState.playerBankSelected}
            buttonStyle="solid"
            css={BanksCollapseStyle.bankOptions}
            onChange={({ target: { value } }) => handleBankSelected(value)}
          >
            {isLoading ? (
              <Spin />
            ) : (
              <>
                {bankList?.map((item, index) => (
                  <Radio.Button value={item} key={index}>
                    <span css={BanksCollapseStyle.bankItem}>
                      <span css={BanksCollapseStyle.bankContent}>
                        <span className="label">{t("bank")}:</span>
                        <Tooltip title={item.bank_name}>
                          <span className="value">{item.bank_name}</span>
                        </Tooltip>
                      </span>

                      <span className="bank-title-wrapper">
                        <span className="bank-title">
                          {t("bankAccount.lastNumber")}:
                        </span>
                        <span>{item.bank_account_no.slice(-4)}</span>
                      </span>
                    </span>
                  </Radio.Button>
                ))}

                {Array.from({
                  length: MAX_TOTAL_BANKS - (bankList?.length || 0),
                }).map((_, index) => (
                  <Button
                    key={`btn-add-bank-${index + 1}`}
                    css={BanksCollapseStyle.btnAddBank}
                    onClick={handleAddMemberBank}
                  >
                    <img loading="lazy" src={IconAddBank} alt="" />
                    {t("bankAccount.add")}
                  </Button>
                ))}
              </>
            )}
          </Radio.Group>
        </div>
      ),
    },
  ];

  return (
    <div css={BanksCollapseStyle.self}>
      <Collapse
        accordion
        items={items}
        expandIcon={() => null}
        onChange={(value) => setCollapseActiveKey(value as string[])}
      />
    </div>
  );
};

export default BanksCollapse;
