import { ContextAction } from "types";

import { DepositContextAction, DepositState } from "./deposit.context";

export const depositReducer = (
  state: DepositState,
  action: ContextAction<DepositContextAction, DepositState>,
): DepositState => {
  switch (action.type) {
    case "FORM_SETTING":
      return {
        ...state,
        formSetting: action.payload.formSetting,
      };

    default:
      return state;
  }
};
