/** @jsxImportSource @emotion/react */
import { useQuery } from "@tanstack/react-query";
import { gameApi } from "api/game";
import { QueryKeys } from "constants/queryKeys";
import handleLoginGame from "helpers/handleLoginGame";
import i18n from "i18n";

import { ItemMenu } from "../list-menu";
import { HotGameStyle } from "./index.style";

type Props = {
  menuItem: ItemMenu;
  isActive: boolean;
};

function HotGame({ menuItem, isActive }: Props) {
  const { data: username } = useQuery<string>([QueryKeys.username]);
  const { data } = useQuery(["HOT-GAME-WEB"], {
    queryFn: async () => {
      const res = await gameApi.getSubGameList({
        page: 1,
        size: 10,
        featured: "1",
      });
      return res;
    },
  });
  const hotGame = data?.data || [];
  return (
    <div css={HotGameStyle.index(isActive, i18n.language)}>
      <div className="bg_menuShadow"></div>
      <div className={`subList ${hotGame.length > 5 ? "twoheight" : ""}`}>
        {hotGame.map((item, index) => {
          return (
            <div
              className="item"
              css={HotGameStyle.item(i18n.language)}
              key={index}
              onClick={() => {
                handleLoginGame(item, username as string);
              }}
            >
              <div className="image-hot" />
              <div className="image">
                <img loading="lazy" src={item.picture} alt="" />
              </div>
              <div className="name">{item.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HotGame;
