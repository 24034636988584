import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { queryClient } from "index";

import { UserApi } from ".";

export const getMemberDetail = async (username: string) => {
  const res = await UserApi.getProfile({ username });
  const resData = res.data;
  if (res.status === ResponseCode.SUCCESS) {
    queryClient.setQueryData([QueryKeys.MemberDetail, username], resData);
  }
};

export const useGetProfile = (username: string, enabled = true) =>
  useQuery({
    staleTime: Infinity,
    enabled: enabled,
    queryKey: [QueryKeys.MemberDetail, username],
    queryFn: async () => {
      const token = localStorage.getItem("token");
      const response = await UserApi.getProfile({ username });
      if (response.status !== ResponseCode.SUCCESS) {
        throw new Error("username invalid");
      }
      queryClient.setQueryData([QueryKeys.isAuthenticated], true);
      queryClient.setQueryData([QueryKeys.username], username);
      queryClient.setQueryData([QueryKeys.token], token);
      return response.data;
    },
  });
