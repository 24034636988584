import { css } from "@emotion/react";

import { media } from "./breakpoints";

export const reset = css`
  /* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */
  * {
    font-family: "Inter", sans-serif;
  }
  *:not(.non-reset *) {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  a img {
    max-width: 100%;
    height: auto;
  }
  body {
    margin: 0;
    padding: 0px;
    font-size: 13px;
    font-family:
      Arial,
      Microsoft JhengHei;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ${media.md} {
    * {
      touch-action: manipulation;
      overscroll-behavior-y: none;
      font-size: 14px;
    }
    div {
      overscroll-behavior-y: none;
    }

    main {
      overscroll-behavior-y: none;
    }

    body {
      overscroll-behavior-y: none;
    }
  }

  .center-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .abcxyz {
    inset: 100px auto auto 70.1875px !important ;
  }
`;
