import { css } from "@emotion/react";

import { BottomItemType } from "./list-bottom";

export const BottomTabsStyle = {
  index: css`
    z-index: 10;
    .footer_mask_all {
      top: 0;
      bottom: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0);
      position: fixed;
      z-index: 48763;
      overflow: auto;
      display: none;
      padding: 20px 0;
      box-sizing: border-box;
    }
  `,
  content: css`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e1e1e2;
    height: 42px;
    z-index: 10;
  `,
  item: (item: BottomItemType, language: string) => css`
    float: left;
    text-decoration: none;
    width: 20%;
    height: 100%;
    text-align: center;
    padding-top: 5px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    background-color: #fff;

    &.active {
      h5 {
        color: #0c5d91;
      }
      &::before {
        background-position-y: bottom !important;
      }
    }

    h5 {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 0.8em;
      line-height: 1em;
      font-weight: normal;
    }
    &::before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 20px;
      height: 20px;
      margin-bottom: 3px;
    }
    &.btn_footer_home {
      &::before {
        width: 22px;
        height: 22px;
        content: "";
        display: block;
        margin: 0 auto;
        margin-bottom: 1px;
        background: url(${"/common/" + item.icon}) no-repeat top;
        background-size: 100% auto;
      }
    }
    &.btn_footer_gift {
      &::before {
        content: "";
        display: block;
        margin: 0 auto;
        background: url(${"/common/" + item.icon}) no-repeat center;
        background-size: auto 100%;
        height: 41px;
        width: 64px;
        margin-top: -13px;
        margin-bottom: -5px;
      }
    }
    &.btn_footer_serv {
      &::before {
        background: url(${"/common/" + item.icon}) no-repeat top;
        background-size: 100% auto;
      }
    }
    &.btn_footer_DW {
      &::after {
        width: 39px;
        height: 39px;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 16px;
        margin: auto;
        background: url(${"/common/" + item.icon}) no-repeat top;
        background-size: 100% 200%;
      }

      h5 {
        color: #f6843f;
      }
    }
    &.btn_footer_tradeRec {
      &::before {
        background: url(${"/common/" + item.icon}) no-repeat top;
        background-size: 100% auto;
      }
    }
    &.btn_footer_menu {
      &::before {
        background: url(${"/common/" + item.icon}) no-repeat top;
        background-size: 100% auto;
      }
    }
  `,
};
