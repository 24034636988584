/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { UserApi } from "api/user";
import { QueryKeys } from "constants/queryKeys";
import { formatCurrency } from "helpers/formatCurrency";
import { useRef } from "react";
import { BalanceType } from "types/user";

import { GameMenuStyle } from "./index.style";
interface props {
  username?: string;
}
function GameMenu({ username }: props) {
  const { data } = useQuery<BalanceType>([QueryKeys.GetBalanceDV], {
    queryFn: async () => {
      const res = await UserApi.getMemberBalance({ username: username + "" });
      return res;
    },
    enabled: username ? true : false,
    refetchOnWindowFocus: false,
  });

  const modalRef = useRef<HTMLLIElement | null>(null);

  return (
    <li ref={modalRef} css={GameMenuStyle.index} id={"GameMenu"}>
      <div className="btn_memberPMenu">
        <span className="value">
          ${" "}
          <span className="ng-binding">
            {formatCurrency(Math.trunc(Number(data?.total || 0))) || 0}
          </span>
        </span>
      </div>
    </li>
  );
}

export default GameMenu;
