import { css } from "@emotion/react";

export const BlankLayoutStyle = {
  self: css`
    /*  */
  `,

  main: css`
    /*  */
  `,
};
