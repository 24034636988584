/** @jsxImportSource @emotion/react */

import { QRCode } from "antd";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { styles } from "./index.style";
import { listServices } from "./list-services";

function CustomerServices() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      const headerPosition = 40 + 65;
      const element = document.getElementById("services");
      if (element) {
        if (currentPosition > headerPosition) {
          element.classList.add("fixed");
        } else {
          element.classList.remove("fixed");
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Fragment>
      <div id="services" css={styles.index(i18n.language)}>
        <div className="customerServ">
          <ul>
            {listServices.map((item, index) => {
              return (
                <li
                  onClick={() => {
                    if (!item.path || item.disable) return;
                    window.open(item.path);
                  }}
                  key={index}
                  className="Sevli"
                >
                  <div
                    className="icon"
                    style={{ backgroundPosition: item.position }}
                  />
                  <span>{item.name}</span>
                  {item.isQRCode ? (
                    <div className="customerServDiv">
                      <div className="servDivT">
                        {item.name}
                        <div className="rightCsArrow" />
                      </div>
                      <div className="qrImg">
                        <QRCode
                          bgColor="#fff"
                          value={item.path}
                          className="qr-code"
                          size={85}
                        />
                        <span>{item.value}</span>
                      </div>
                    </div>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
        {/* <div className="CF_Gift" css={styles.CF_Gift}>
          <div className="btn_CAO">
            <img
              loading="lazy"
              src={`/${i18n.language}/desktop/services/icon_CAO.png`}
              alt=""
            />
          </div>
        </div> */}
      </div>
    </Fragment>
  );
}

export default CustomerServices;
