import { css } from "@emotion/react";

export const ExampleStepsStyle = {
  self: css`
    width: 850px;
    margin: 0 auto;
  `,

  stepName: css`
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    font-size: 17px;
    font-weight: bold;
    background-color: #32abff;
    margin-top: 25px;
  `,

  stepTexts: (stepLength = 1) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px 0;
    font-size: 16px;
    width: 100%;
    line-height: 30px;
    & .step-text {
      margin-right: 40px;
      display: flex;
      ${stepLength > 1 && `align-items:center`}
    }
    & .step-num {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      min-width: 25px;
      border-radius: 50%;
      border: 2px solid #fe7b04;
      background-color: #fff;
      margin-right: 5px;
      position: relative;
      ${stepLength === 1 &&
      `
        &::after {
          content: "";
          position: absolute;
          display: block;
          inset: 0;
          margin: auto;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: #fe7b04;
        }
        `}
    }
  `,

  content: css`
    position: relative;
    padding: 20px 0;
    text-align: center;
  `,

  btnPrev: css`
    position: absolute;
    left: -90px;
    width: 76px;
    height: 76px;
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    border-radius: 50%;
  `,

  btnStep: css`
    width: 38px;
    height: 100%;
    border: none !important;
    background-color: #8fc6ec !important;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: none !important;
    }
    svg {
      width: 25px;
    }
  `,

  btnNext: css`
    position: absolute;
    right: -90px;
    width: 76px;
    height: 76px;
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  `,

  pageNum: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: #7f7f7f;
    font-size: 18px;
  `,

  stepPageContent: css`
    & .title {
      text-align: center;
      color: #527fbd;
      font-weight: bold;
      font-size: 23px;
      padding: 45px 0 12px 16px;
    }

    & .step {
      &-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 40px;
        position: relative;
        & .step-num {
          position: absolute;
          top: -10px;
          left: -20px;
          font-size: 38px;
          transform: skewX(-5deg);
          color: #f19d01;
          font-weight: bold;
        }
        & .step-image {
          min-width: 130px;
          height: 130px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ededed;
          img {
            width: 65px;
            height: 65px;
          }
        }
        & .step-desc {
          font-size: 19px;
          color: #535353;
        }
      }
    }
  `,
};
