/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import CustomerServices from "components/desktop/CustomerServices";
import { Footer } from "components/desktop/Footer";
import { Header } from "components/desktop/Header";
import MainMenu from "components/desktop/MainMenu";
import { Outlet } from "react-router-dom";

export const DefaultLayout = () => {
  return (
    <div css={css``}>
      <Header />
      <MainMenu />
      <main css={css``}>
        <Outlet />
      </main>
      <Footer />
      <CustomerServices />
    </div>
  );
};
