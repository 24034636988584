import { css } from "@emotion/react";

export const styles = {
  index: (language: string) => css`
    display: block;

    &.fixed {
      .customerServ {
        position: fixed;
        top: 0;
      }
      .CF_Gift {
        top: 260px;
        position: fixed;
      }
    }

    .customerServ {
      position: absolute;
      right: 0;
      top: 105px;
      width: 80px;
      height: 240px;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.3);
      &::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
      }

      ul {
        display: flex;
        flex-direction: column;
        height: 100%;

        .Sevli {
          position: relative;
          cursor: pointer;
          color: #fff;
          font-size: 14px;
          line-height: 1em;
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .icon {
            background: url(${`/common/desktop/menu/icon_indexImg.png`})
              no-repeat;
            width: 40px;
            height: 40px;
            margin: 0 auto 2px;
          }

          &:hover {
            .customerServDiv {
              display: block;
            }
          }
        }
      }
    }
    .customerServDiv {
      position: absolute;
      right: 100px;
      background-color: rgba(255, 255, 255, 0.9);
      top: 0;
      width: 170px;
      display: none;
      text-align: center;
      opacity: 1;

      .servDivT {
        height: 40px;
        line-height: 40px;
        border-bottom: 2px solid #fff;
        font-size: 14px;
        color: #1a7ecc;
        position: relative;

        .rightCsArrow {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid rgba(255, 255, 255, 0.7);
          position: absolute;
          top: 10px;
          right: -10px;
        }
      }
      .qrImg {
        padding: 10px 0px;

        .qr-code {
          margin: 5px auto;
          display: block;
          background: #fff;

          canvas {
            width: 85px !important;
            height: 85px !important;
          }
        }

        span {
          color: #000;
        }
      }
    }
  `,

  CF_Gift: css`
    width: 141px;
    position: absolute;
    right: 0;
    top: 365px;
    z-index: 50;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: "absolute";
  `,
};
