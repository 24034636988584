import { css } from "@emotion/react";

export const WithdrawAttentionStyle = {
  self: css`
    background-color: #fff;
  `,

  header: css`
    height: 36px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14.96px;
    gap: 8px;
    img {
      width: 18px;
    }
  `,

  desc: css`
    padding: 12px 0;
    ol {
      li {
        /* display: list-item;
        list-style-position: inside;
        list-style-type: decimal !important; */
        padding: 0 15px;
        font-size: 14.96px;
        display: flex;
        gap: 5px;
        & .decimal {
          min-width: 23px;
          max-width: 23px;
        }
        & .highlight {
          color: #ff0000;
        }
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }
  `,
};
