import React, { createContext, Dispatch, useContext } from "react";
import { ContextAction } from "types";
import { PlayerBankType } from "types/bank";

import { withdrawReducer } from "./withdraw.reducer";

export type WithdrawContextAction = "PLAYER_BANK_SELECT";

export interface WithdrawState {
  playerBankSelected: PlayerBankType | null;
}

interface IWithdrawContext {
  state: WithdrawState;
  dispatch: Dispatch<
    ContextAction<WithdrawContextAction, WithdrawState>
  > | null;
}

export const initialState: WithdrawState = {
  playerBankSelected: null,
};

export const WithdrawContext = createContext<IWithdrawContext>({
  state: initialState,
  dispatch: null,
});

export const WithdrawContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = React.useReducer(withdrawReducer, initialState);
  return (
    <WithdrawContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </WithdrawContext.Provider>
  );
};

export const useWithdrawContext = () =>
  useContext<IWithdrawContext>(WithdrawContext);
