/** @jsxImportSource @emotion/react */

import IconMaintain from "assets/desktop/images/icon_maintain.png";
import { useTranslation } from "react-i18next";

import { WithdrawAttentionStyle } from "./index.style";

const WithdrawAttention = () => {
  const { t } = useTranslation(["payment"]);

  return (
    <div css={WithdrawAttentionStyle.self}>
      <div css={WithdrawAttentionStyle.header}>
        <img loading="lazy" src={IconMaintain} alt="" />
        {t("withdrawal.attention.self")}
      </div>
      <div css={WithdrawAttentionStyle.desc}>
        <ol>
          {Array.from({ length: 3 }).map((_, index) => (
            <li key={index}>
              <div className="decimal">{index + 1}.</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`withdrawal.attention.limit.${index + 1}`),
                }}
              ></div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default WithdrawAttention;
