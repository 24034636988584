export const handleNewWindow = (url: string, w: number, h: number) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;
  const left = window.screen.width / 2 - w / 2 + dualScreenLeft;
  const top = window.screen.height / 2 - h / 2 + dualScreenTop;
  return window.open(
    url,
    "newWindow",
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left,
  );
};
