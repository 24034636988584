import { css } from "@emotion/react";

export const GameMenuStyle = {
  index: css`
    position: relative;

    .btn_memberPMenu {
      cursor: pointer;
      padding-right: 10px;
      margin-right: 10px;
      position: relative;

      &::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
      }

      .value {
        margin: 0 5px;
        color: #ffde00;
        font-size: 18px;
        line-height: normal;
        white-space: nowrap;
      }
    }

    .pArrowOn {
      border-bottom: 7px solid rgba(255, 255, 255, 1);
      border-top: 0px !important;
    }

    .memberPArrow {
      position: absolute;
      right: -4px;
      top: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid rgba(255, 255, 255, 0.7);
      margin: auto;
    }

    .memberPMenu {
      position: absolute;
      top: 40px;
      z-index: 9;
      font-size: 14px;
      display: none;
      line-height: normal;
      right: -180px;

      &.active {
        display: block;
      }

      .memberPMenuArrow {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #333;
        position: absolute;
        top: -9px;
        right: 213px;

        &::after {
          content: "";
          display: block;
          height: 0;
          clear: both;
          visibility: hidden;
        }
      }

      .memberPMenuT {
        display: flex;
        flex-direction: column;
        width: 250px;
        padding: 5px 15px;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 0;

          div {
            &:nth-of-type(2) {
              font-weight: 600;
            }
          }
        }
      }
    }
  `,
};
