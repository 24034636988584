/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Modal } from "components/common/Modal";
import BottomTabs from "components/mobile/BottomTabs";
import { Outlet } from "react-router-dom";

export const DefaultLayout = () => {
  return (
    <div
      css={css`
        width: 100vw;
        position: relative;
      `}
    >
      <main
        css={css`
          position: fixed;
          top: 40px;
          left: 0;
          bottom: 42px;
          width: 100%;
        `}
        id="main-app"
      >
        <Outlet />
      </main>
      <Modal />
      <BottomTabs />
    </div>
  );
};
