import axiosRequest from "api";
import { QueryKeys } from "constants/queryKeys";
import { ResponseCode } from "constants/response";
import { handleDomain } from "helpers/handleDomain";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  ChangePasswordFormType,
  ForgotPasswordFormType,
  LoginFormType,
  LoginResponse,
  RegisterPayload,
  RequestOTPType,
  VerifyTokenPayload,
} from "./type";

export const AuthApi = {
  // 1: successful => return
  // 2: failed => throw error
  login: async (payload: LoginFormType) => {
    const res = await axiosRequest.post<LoginResponse>(
      "/",
      payloadHelper(QueryKeys.MemberLogin, payload.username, payload),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },
  async verifyToken(typename: string, payload: VerifyTokenPayload) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("VerifyToken", typename, payload),
    );
    return data;
  },

  async register(typename: string, payload: RegisterPayload) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("MemberRegister", typename, {
        ...payload,
        domain: handleDomain(payload.referral),
      }),
    );
    if (data.status === ResponseCode.SUCCESS) {
      return {
        ...data,
        data: {
          ...data,
          ...payload,
        },
      };
    }
    throw new Error(data.msg);
  },

  async forgotPassword(typename: string, payload: ForgotPasswordFormType) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ForgotPassword", typename, payload),
    );
    if (data.status !== ResponseCode.SUCCESS) {
      throw new Error(data.msg);
    }
    return data;
  },

  async changePassword(typename: string, payload: ChangePasswordFormType) {
    const response = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ChangePassword", typename, payload),
    );
    return response.data;
  },

  requestOTP: (payload: RequestOTPType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("RequestOTP", payload.username, payload),
    ),
};
