import { css } from "@emotion/react";
import { CATEGORY_CODE } from "constants/desktop-game/type";
import { SubGameType } from "types/game";

export const DropdownMenuStyle = {
  index: (isActive: boolean, language: string) => css`
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    z-index: 8;
    width: 100%;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.95);

    .bg_menuShadow {
      width: 100%;
      position: absolute;
      top: 0px;
      background-image: url(${`common/desktop/menu/bg_menuShadow.png`});
      height: 4px;
      z-index: 8;
    }

    .subList {
      margin: 0 auto;
      position: relative;
      width: 1000px;
      height: 100%;
      height: ${isActive ? "170px" : 0};
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;
    }

    .game-${CATEGORY_CODE.LIVE_CASINO} {
      height: ${isActive ? "340px" : 0} !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .item {
        height: 50%;
        width: calc(1000px / 5) !important;
        gap: 4px 0;
        .Darkened2 {
          width: 200px !important;
        }
      }
    }

    .game-${CATEGORY_CODE.GAMES} {
      height: ${isActive ? "340px" : 0} !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      width: 1200px !important;
      .item {
        height: 50%;
        width: calc(1200px / 6) !important;
        gap: 4px 0;
        .Darkened2 {
          width: 200px !important;
        }
      }
    }

    .game-${CATEGORY_CODE.LOTTERY},
      .game-${CATEGORY_CODE.E_SPORT},.game-${CATEGORY_CODE.CHESS_GAME} {
      height: ${isActive ? "340px" : 0} !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .item {
        .Darkened2 {
          width: 333px;
        }
      }
    }
    .game-${CATEGORY_CODE.FISHING} {
      height: ${isActive ? "340px" : 0} !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      .item {
        height: 50%;
        width: calc(1000px / 4) !important;
        gap: 4px 0;
      }
    }
  `,
  item: (item: SubGameType, language: string) => css`
    overflow: hidden;
    position: relative;
    cursor: pointer;
    float: left;
    height: 100%;
    cursor: pointer;

    &:hover {
      background: linear-gradient(
        to bottom,
        rgba(219, 232, 251, 1) 0%,
        rgba(219, 232, 251, 0.2) 100%
      );
    }

    .Darkened2 {
      ${item.picture.replace("/desktop/", `/common/desktop/`)}
      width: 250px;
      height: 100%;
    }
    .listLogo {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      right: 0;
      height: 100px;
      top: 40px;

      .img_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        max-width: 94px;

        &.img_logo-WG {
          max-width: 48px;
        }

        img {
          max-width: 55%;
          margin-left: 8px;
        }
      }
      .listName {
        letter-spacing: -0.5px;
        white-space: nowrap;
        color: #091437;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: wrap;
      }
    }
  `,
};
