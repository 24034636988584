import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
const languageDefault = localStorage.getItem("i18nLng");
i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: languageDefault || "vi",
    initImmediate: false,
    fallbackLng: "vi",
    // defaultNS: "home",
    backend: {
      loadPath: `/translation/{{lng}}/{{ns}}.json`,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    saveMissing: true,
  });

export default i18n;
