import { css } from "@emotion/react";

import { LoadingProps } from ".";

export const LoadingStyle = {
  self: ({ height, center, width }: LoadingProps) => css`
    width: 100%;
    & .ant-spin {
      ${height &&
      css`
        height: ${typeof height === "string" ? height : `${height}px`};
        width: ${typeof width === "string" ? width : `${width}px`};
      `}
      ${center &&
      css`
        display: grid;
        place-items: center;
      `}
    }
  `,
};
