import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  AnnouncementFormType,
  AnnouncementResponseType,
  MemberMessageFormType,
  MemberMessageResponseType,
  ReadPlayerMessageForm,
} from "./type";

export const AnnouncementApi = {
  getAnnouncement: async (payload: AnnouncementFormType) => {
    const res = await axiosRequest.post<AnnouncementResponseType>(
      "/",
      payloadHelper("Announcement", null, payload),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },
  getMemberMessage: async (payload: MemberMessageFormType) => {
    const res = await axiosRequest.post<MemberMessageResponseType>(
      "/",
      payloadHelper("MemberMessage", String(payload.username), payload),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },
  readPlayerMessage: async (payload: ReadPlayerMessageForm) => {
    const res = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ReadPlayerMessage", String(payload.username), payload),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return payload.message_id;
    }
    throw new Error(res.data.msg);
  },
  readAllMessagePlayer: async (payload: { username: string }) => {
    const res = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ReadAllPlayerMessage", String(payload.username), payload),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },
};
