import { css, Theme } from "@emotion/react";
import { media } from "styles/breakpoints";

export const HeaderUserLayoutStyle = {
  wrapper: css`
    width: 100%;
    height: 60px;
    background-color: #0c5d91;
  `,

  self: css`
    width: 950px;
    height: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 25px;
    ${media.xl} {
      width: 100%;
    }
  `,

  title: ({ colors }: Theme) => css`
    color: ${colors.white};
    font-size: 18px;
    font-weight: bold;
  `,

  tabs: css`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
  `,

  tabItem: ({ colors }: Theme) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6caed8;
    height: 100%;
    position: relative;
    height: 100%;
    .maintain-icon {
      width: 20px;
    }
    a {
      font-size: 13px;
      color: ${colors.white};
      text-decoration: none;
      font-size: 13px;
      margin-top: 15px;
    }
    span {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #e5e5e5;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin: 0 auto;
      display: none;
    }
    & .active {
      margin-top: 0;
    }
    & .active ~ span {
      display: block;
    }
  `,

  line: css`
    background-color: #3480b1 !important;
    width: 1px;
    height: 15px;
    margin-top: 15px;
  `,

  tooltipDeposit: css`
    display: inline-flex;
    gap: 10px;
    color: #fff;
    align-items: center;
    font-size: 13px;
    white-space: pre-wrap;
    line-height: 17px;
    img {
      width: 15px;
    }
  `,
};
