export interface keyBoardType {
  value: string | number;
  icon: string;
  colSpan?: number;
}

export const dataKeyboard1: keyBoardType[] = [
  {
    value: "1",
    icon: "/mobile/keyboard/btn_number1.svg",
  },
  {
    value: "2",
    icon: "/mobile/keyboard/btn_number2.svg",
  },
  {
    value: "3",
    icon: "/mobile/keyboard/btn_number3.svg",
  },
  {
    value: "4",
    icon: "/mobile/keyboard/btn_number4.svg",
  },
  {
    value: "5",
    icon: "/mobile/keyboard/btn_number5.svg",
  },
  {
    value: "DELETE",
    icon: "/mobile/keyboard/btn_arrowCRB.svg",
  },
];

export const dataKeyboard2: keyBoardType[] = [
  {
    value: "6",
    icon: "/mobile/keyboard/btn_number6.svg",
  },
  {
    value: "7",
    icon: "/mobile/keyboard/btn_number7.svg",
  },
  {
    value: "8",
    icon: "/mobile/keyboard/btn_number8.svg",
  },
  {
    value: "9",
    icon: "/mobile/keyboard/btn_number9.svg",
  },
  {
    value: "0",
    icon: "/mobile/keyboard/btn_number0.svg",
    colSpan: 2,
  },
];
