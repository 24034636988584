export enum DepositMethod {
  EBanking = 1,
  AtTheCounter = 2,
  ATM = 3,
  Online = 4,
  QRPay = 5,
  Momo = 6,
  ZaloPay = 7,
  Crypto = 8,
  ViettelPay = 9,
  USDT = 13,
}

export enum DepositType {
  OFFLINE = 1,
  ONLINE = 2,
}
