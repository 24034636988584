import { SubGameType } from "types/game";

import { CATEGORY_CODE } from "./type";

export const listESport: SubGameType[] = [
  // {
  //   game_code: "",
  //   name: "IBC",
  //   provider_code: "IBC",
  //   provider_name: "IBC",
  //   type: CATEGORY_CODE.E_SPORT,
  //   featured: "",
  //   new_arrive: "",
  //   picture: `
  //   background:url("/desktop/e-sport/bg_topESports.png") no-repeat;
  //   background-position: left top !important;
  //   `,
  //   icon: "/desktop/e-sport/logo_IBC.png",
  // },
  {
    game_code: "",
    name: "LH",
    provider_code: "LH",
    provider_name: "LH",
    type: CATEGORY_CODE.E_SPORT,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/e-sport/bg_topESports.png") no-repeat;
    background-position: right top !important;
    `,
    icon: "/desktop/e-sport/logo_LH.png",
  },
];
