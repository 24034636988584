/** @jsxImportSource @emotion/react */

import { useQueryClient } from "@tanstack/react-query";
import { Language } from "enums/language";
import { closeLoadingApp, openLoadingApp } from "helpers/loadingApp";
import { find } from "lodash";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChangeLanguageStyle } from "./index.style";

interface LanguageItem {
  name: string;
  code: string;
  icon: string;
}

const listLanguages: LanguageItem[] = [
  {
    name: "English",
    code: Language.ENGLISH,
    icon: "/translation/icon/en.png",
  },
  {
    name: "Tiếng Việt",
    code: Language.VIETNAM,
    icon: "/translation/icon/vi.png",
  },
];
function ChangeLanguage() {
  const queryClient = useQueryClient();
  const activeLanguage =
    localStorage.getItem("i18nLng") || listLanguages[1].code;
  const { i18n } = useTranslation();

  const [code, setCode] = useState(activeLanguage);
  const [open, setOpen] = useState<boolean>(false);

  const onChangeLanguage = (item: LanguageItem) => {
    openLoadingApp();
    localStorage.setItem("i18nLng", item.code);
    i18n.changeLanguage(item.code);
    setCode(item.code);
    setOpen(false);
    queryClient.refetchQueries();
    const element = document.getElementsByTagName("html");
    if (element) {
      element[0].lang = item.code;
    }
    setTimeout(() => {
      closeLoadingApp();
    }, 1000 * 0.5);
  };

  const currentItem = find(
    listLanguages,
    (item: LanguageItem) => item.code === code,
  );
  return (
    <Fragment>
      <div css={ChangeLanguageStyle.index}>
        <img onClick={() => setOpen(!open)} src={currentItem?.icon} alt="" />
        <div className={`popup ${open ? "open" : ""}`}>
          {listLanguages.map((item, index) => {
            const active = item.code === code;
            return (
              <div
                onClick={() => {
                  if (active) return;
                  onChangeLanguage(item);
                }}
                className={`item ${active ? "active" : ""}`}
                key={index}
              >
                <img src={item.icon} alt="" />
                <span>{item.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      {open && (
        <div
          onClick={() => setOpen(false)}
          css={ChangeLanguageStyle.overplay}
        />
      )}
    </Fragment>
  );
}

export default ChangeLanguage;
