import { SubGameType } from "types/game";

export const listFishing: SubGameType[] = [
  {
    game_code: "",
    name: "Jili",
    provider_code: "JILI",
    provider_name: "Jili",
    type: "FH",
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/fishing/bg_topFighter.png") no-repeat;
    background-position: left top !important;
    `,
    icon: "/desktop/fishing/logo_JILI.png",
  },
  {
    game_code: "",
    name: "JDB",
    provider_code: "JDB",
    provider_name: "JDB",
    type: "FH",
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/fishing/bg_topFighter.png") no-repeat;
    background-position: right top !important;
    `,
    icon: "/desktop/fishing/logo_JDB.png",
  },
  {
    game_code: "",
    name: "V8 Poker",
    provider_code: "V8",
    provider_name: "V8 Poker",
    type: "FH",
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/fishing/bg_topFighter.png") no-repeat;
    background-position-y: bottom;
    `,
    icon: "/desktop/fishing/logo_V8.png",
  },
  {
    game_code: "",
    name: "CQ9",
    provider_code: "CQ9",
    provider_name: "CQ9",
    type: "FH",
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/fishing/bg_topFighter.png") no-repeat;
    background-position: -250px top !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/fishing/logo_CQ9.png",
  },
  {
    game_code: "",
    name: "KA",
    provider_code: "KA",
    provider_name: "KA",
    type: "FH",
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/fishing/bg_topFighter.png") no-repeat;
    background-position: -500px top !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/fishing/logo_KA.png",
  },
  // {
  //   game_code: "",
  //   name: "Dragon Slots",
  //   provider_code: "DGG",
  //   provider_name: "Dragon Slots",
  //   type: "FH",
  //   featured: "",
  //   new_arrive: "",
  //   picture: `
  //   background:url("/desktop/fishing/bg_topFighter.png") no-repeat;
  //   background-position: -250px top !important;
  //   `,
  //   icon: "/desktop/fishing/logo_DS.png",
  // },
];
