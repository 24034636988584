/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { AnnouncementApi } from "api/announcement";
import { AnnouncementResponseType } from "api/announcement/type";
import { ReactComponent as ChevronDownIcon } from "assets/common/icons/chevron-down.svg";
import EmptyComponent from "components/common/EmptyComponent";
import Loading from "components/common/Loading";
import { QueryKeys } from "constants/queryKeys";
import _map from "lodash/map";
import { useState } from "react";
import { Translation } from "react-i18next";
import { AnnouncementItemType } from "types/announcement";

import AnnouncementHeader from "./AnnouncementHeader";
import { AnnouncementPageStyle } from "./index.style";

interface DataType extends AnnouncementItemType {
  key: React.Key;
  title: any;
  content: any;
}

const columns: ColumnsType<DataType> = [
  {
    title: (
      <Translation ns={["common"]}>
        {(t) => <>{t("title", { ns: "common" })}</>}
      </Translation>
    ),
    dataIndex: "title",
    key: "title",
  },
  {
    title: (
      <Translation ns={["common"]}>
        {(t) => <>{t("time", { ns: "common" })}</>}
      </Translation>
    ),
    dataIndex: "start",
    key: "start",
    width: 200,
  },
];

const AnnouncementPage = () => {
  const [rowActive, setRowActive] = useState<number | undefined>(0);

  const { data: response, isLoading } = useQuery<AnnouncementResponseType>(
    [QueryKeys.Announcement],
    {
      queryFn: async () => {
        return await AnnouncementApi.getAnnouncement({});
      },
      staleTime: Infinity,
    },
  );
  const data = response?.data;
  const dataTable: DataType[] = _map(data, (item, index) => {
    return {
      ...item,
      key: index + 1,
      title: (
        <div css={AnnouncementPageStyle.headTitleWrapper}>
          <div css={AnnouncementPageStyle.headTitle}>{item.title}</div>
          <ChevronDownIcon
            css={AnnouncementPageStyle.downIcon(rowActive === index + 1)}
          />
        </div>
      ),
    };
  });

  return (
    <div css={AnnouncementPageStyle.self}>
      <AnnouncementHeader />
      <div css={AnnouncementPageStyle.content}>
        <div css={AnnouncementPageStyle.tableWrapper}>
          {isLoading ? (
            <Loading center height={400} />
          ) : (
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <div
                    css={AnnouncementPageStyle.expandContent}
                    dangerouslySetInnerHTML={{
                      __html: record.content,
                    }}
                  ></div>
                ),
                showExpandColumn: false,
                expandRowByClick: true,
                expandedRowKeys: rowActive ? [rowActive] : [],
                onExpand: (record, event) => {
                  setRowActive(record ? Number(event.key) : undefined);
                },
                expandedRowClassName: ({ key }, index, indent) => {
                  return key === rowActive ? "active" : "";
                },
              }}
              dataSource={dataTable}
              css={AnnouncementPageStyle.table}
              pagination={false}
              bordered
              locale={{
                emptyText: (
                  <div css={AnnouncementPageStyle.empty}>
                    <EmptyComponent />
                  </div>
                ),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementPage;
