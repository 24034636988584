/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { gameProviderJson } from "constants/desktop-game";
import { QueryKeys } from "constants/queryKeys";
import handleLoginGame from "helpers/handleLoginGame";
import i18n from "i18n";

import { ItemMenu } from "../list-menu";
import { DropdownMenuStyle } from "./index.style";

type Props = {
  menuItem: ItemMenu;
  isActive: boolean;
};

function DropdownMenu({ menuItem, isActive }: Props) {
  const { data: username } = useQuery<string>([QueryKeys.username]);
  const games = gameProviderJson[menuItem.code + ""] || [];
  if (!games?.length) return null;
  return (
    <div css={DropdownMenuStyle.index(isActive, i18n.language)}>
      <div className="bg_menuShadow"></div>
      <div className={`subList game-${menuItem.code}`}>
        {Array.isArray(games) &&
          games.map((item, index) => {
            return (
              <div
                className="item"
                css={DropdownMenuStyle.item(item, i18n.language)}
                key={index}
                onClick={() => {
                  if (menuItem.isSubGame && item.provider_code !== "GFGD") {
                    return window.open(
                      `${menuItem.code}/${item.provider_code}`,
                      "subGame",
                    );
                  }
                  handleLoginGame(item, username as string);
                }}
              >
                <div className="Darkened2" />
                <div className="listLogo">
                  <div className={`img_logo img_logo-${item.provider_code}`}>
                    <img loading="lazy" src={"/common" + item.icon} alt="" />
                  </div>
                  <div className="listName">{item.provider_code}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default DropdownMenu;
