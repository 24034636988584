import { SubGameType } from "types/game";

import { CATEGORY_CODE } from "./type";

export const listCockFighting: SubGameType[] = [
  {
    game_code: "",
    name: "WS168",
    provider_code: "WS16",
    provider_name: "WS168",
    type: CATEGORY_CODE.COCK_FIGHTING,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/cock-fighting/bg_cockFighting.png") no-repeat;
    background-position: -500px top !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/cock-fighting/logo_WS168.png",
  },
];
