import { RouteObject } from "react-router-dom";

import { DesktopRoutes } from "./desktopRoutes";
import { MobileRoutes } from "./mobileRoutes";

export const isMobile = /iPhone|iPad|iPod|Android|tablet/i.test(
  navigator.userAgent,
);

const routesConfig: RouteObject[] = isMobile ? MobileRoutes : DesktopRoutes;

export default routesConfig;
