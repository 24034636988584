import { SubGameType } from "types/game";

import { CATEGORY_CODE } from "./type";

export const listSportGame: SubGameType[] = [
  {
    game_code: "",
    name: "CMD",
    provider_code: "CMD",
    provider_name: "CMD",
    type: CATEGORY_CODE.SPORT,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/sport/bg_topSports.png") no-repeat;
    background-position: right top !important;
    `,
    icon: "/desktop/sport/logo_CMD.png",
  },
  {
    game_code: "",
    name: "IBC",
    provider_code: "IBC",
    provider_name: "IBC",
    type: CATEGORY_CODE.SPORT,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/sport/bg_topSports.png") no-repeat;
    background-position: -500px top !important;
    `,
    icon: "/desktop/sport/logo_IBC.png",
  },
  {
    game_code: "",
    name: "SBO",
    provider_code: "SBO",
    provider_name: "SBO",
    type: CATEGORY_CODE.SPORT,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/sport/bg_topSports.png") no-repeat;
    background-position: left top !important;
    `,
    icon: "/desktop/sport/logo_sbo.png",
  },
];
