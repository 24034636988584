export const openLoadingApp = () => {
  const element = document.getElementById("loading-app");
  if (element) {
    element.style.display = "flex";
    setTimeout(() => {
      element.style.display = "none";
    }, 1000 * 5);
  }
};

export const closeLoadingApp = () => {
  const element = document.getElementById("loading-app");
  if (element) {
    element.style.display = "none";
  }
};
