/** @jsxImportSource @emotion/react */

import { PATH } from "constants/path";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { FooterOpenStyle } from "./index.style";
type Props = {
  open: boolean;
  onClose: () => void;
};

function FooterOpen({ open, onClose }: Props) {
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  return (
    <div css={FooterOpenStyle.index(open)}>
      <div onClick={onClose} className="overplay" />
      <div className="content">
        <div
          className="btn_footer_deposit"
          onClick={() => {
            navigate(PATH.User.Deposit);
          }}
        >
          <div className="img_deposit" />
          <span>{t("mobile.money.Deposit")}</span>
        </div>
        <div
          className="btn_footer_withdrawal"
          onClick={() => {
            navigate(PATH.User.Withdraw);
            onClose();
          }}
        >
          <div className="img_withdrawal" />
          <span>{t("mobile.money.Withdraw")}</span>
        </div>
      </div>
    </div>
  );
}

export default FooterOpen;
