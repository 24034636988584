import { SubGameType } from "types/game";

import { listBoardGame } from "./game-board";
import { listCockFighting } from "./game-cock-fighting";
import { listESport } from "./game-e-sport";
import { listFishing } from "./game-fishing";
import { listLiveCasino } from "./game-live-casino";
import { listLottery } from "./game-lottery";
import { listSlots } from "./game-slots";
import { listSportGame } from "./game-sport";
import { CATEGORY_CODE } from "./type";

interface JSONTYPE {
  [x: string]: SubGameType[];
}
export const gameProviderJson: JSONTYPE = {
  [CATEGORY_CODE.SPORT]: listSportGame,
  [CATEGORY_CODE.LIVE_CASINO]: listLiveCasino,
  [CATEGORY_CODE.GAMES]: listSlots,
  [CATEGORY_CODE.LOTTERY]: listLottery,
  [CATEGORY_CODE.E_SPORT]: listESport,
  [CATEGORY_CODE.FISHING]: listFishing,
  [CATEGORY_CODE.CHESS_GAME]: listBoardGame,
  [CATEGORY_CODE.COCK_FIGHTING]: listCockFighting,
};
