"use client";

import { ModalTypeValue } from "enums/modalType";
import React, { createContext, Dispatch, useContext } from "react";
import { ContextAction } from "types";

import { reducer } from "./reducer";

export type ContextActionType = "OPENMODAL" | "CHANGELANGUAGE";

export interface AppState {
  isAuthenticated: boolean;
  username: string;
  openModal: ModalTypeValue;
  language: string;
  code?: string;
}

interface IAppContext {
  state: AppState;
  dispatch: Dispatch<ContextAction<ContextActionType, AppState>> | null;
}

export const initialState: AppState = {
  isAuthenticated: false,
  username: "",
  openModal: ModalTypeValue.CLOSE,
  language: "",
};

export const AppContext = createContext<IAppContext>({
  state: initialState,
  dispatch: null,
});

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext<IAppContext>(AppContext);
