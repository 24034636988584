import axios from "axios";
import { useAppContext } from "contexts/app.context";
import { startTransition, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { handleChangeLanguage } from "./handleModal";
import { closeLoadingApp, openLoadingApp } from "./loadingApp";

const languageDefault = localStorage.getItem("i18nLng");
const DEFAULT = "vi";
const LangsKey: {
  [key: string]: string;
} = {
  EN: "en",
  VI: "vi",
};
export const useIP = () => {
  const { state, dispatch } = useAppContext();
  const { i18n } = useTranslation();
  useTranslation(["message", "home", "auth"]);
  const setLocalStorage = (value: string) => {
    localStorage.setItem("i18nLng", value);
    closeLoadingApp();
    handleChangeLanguage(state, dispatch, value);
    const element = document.getElementsByTagName("html");
    if (element) {
      element[0].lang = value;
    }
  };
  const handleLanguage = useCallback(async () => {
    openLoadingApp();
    if (languageDefault) {
      setLocalStorage(languageDefault);
      return;
    }
    try {
      const { data } = await axios.get("https://geolocation-db.com/json/");
      const language = LangsKey[data.country_code as string];

      if (!language) {
        handleChangeLanguage(state, dispatch, DEFAULT);
        i18n.changeLanguage(DEFAULT);
        setLocalStorage(DEFAULT);
        return;
      }
      handleChangeLanguage(state, dispatch, language);
      i18n.changeLanguage(language);
      setLocalStorage(language);
    } catch (error) {
      handleChangeLanguage(state, dispatch, DEFAULT);
      i18n.changeLanguage(DEFAULT);
      setLocalStorage(DEFAULT);
    }
  }, [i18n]);

  useEffect(() => {
    startTransition(() => {
      handleLanguage();
    });
  }, [handleLanguage]);
};
