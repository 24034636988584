/** @jsxImportSource @emotion/react */

import { Form, Input, InputProps } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { Control, Controller, FieldValues } from "react-hook-form";

import { InputCustomStyle } from "./index.style";

export interface InputCustomType extends InputProps {
  name?: string;
  control?: Control<FieldValues | any>;
  errors?: string;
  validate_status?: ValidateStatus;
  defaultValue?: string | number;
  width?: string;
  label?: string;
}

const InputCustom = (props: InputCustomType) => {
  const {
    name = "",
    control,
    errors,
    validate_status,
    defaultValue,
    width,
    label,
  } = props;

  return (
    <>
      {control ? (
        <Form.Item
          css={InputCustomStyle.self}
          label={
            label ? (
              <p style={{ fontSize: "16px", color: "white" }}>{label}</p>
            ) : null
          }
          help={errors}
          validateStatus={validate_status}
          className="input-custom"
          style={{
            width: width ? width : "100%",
          }}
          labelCol={{ span: 24 }}
        >
          <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            render={({ field: { onChange, value } }) => {
              return <Input onChange={onChange} value={value} {...props} />;
            }}
          />
        </Form.Item>
      ) : (
        <Input {...props} css={InputCustomStyle.self} />
      )}
    </>
  );
};

export default InputCustom;
