import { ApiResponse } from "./api";

export interface PromotionListType {
  id: string;
  bonus: string;
  wager: string;
  web: string;
  mobile: string;
  content: string;
  button: string;
  category: string;
}

export interface PromotionsListResponseType extends ApiResponse {
  data: PromotionListType[];
}
export interface PromotionsListStateType {
  promotionsList: PromotionListType[];
  isLoading: boolean;
}

export interface MemberPromotion {
  id: string;
  promotion: string;
  status: string;
  total_turnover: string;
  reward: string;
  create_date: string;
}

export enum READ_VALUE {
  read = "2",
  noRead = "1",
}
