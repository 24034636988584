/** @jsxImportSource @emotion/react */

import { Modal } from "components/common/Modal";
import { Outlet } from "react-router-dom";

import HeaderUserLayout from "./Header";
import { UserLayoutStyle } from "./index.style";

export const UserLayout = () => {
  return (
    <div css={UserLayoutStyle.self}>
      <HeaderUserLayout />
      <main css={UserLayoutStyle.main}>
        <Outlet />
      </main>
      <Modal />
    </div>
  );
};
