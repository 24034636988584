import { css } from "@emotion/react";

export const MWithdrawPageStyle = {
  self: css`
    background-color: #f0eff5;
    min-height: 100vh;
  `,

  bankTitle: css`
    height: 40px;
    color: #648fb2;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  `,

  formWrapper: css`
    //
  `,

  formGroup: css`
    height: 48px;
    border-bottom: 1px solid #ddd;
    padding: 0 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    label {
      min-width: 100px;
      max-width: 100px;
      font-size: 15.84px;
      color: #000;
      display: block;
    }
    & .ant-input {
      &-affix-wrapper {
        border: none !important;
        box-shadow: none !important;
        display: flex;
        align-items: center;
        background-color: unset !important;
        & input {
          background-color: unset !important;
          font-size: 15.84px !important;
          padding: 0 !important;
          &::placeholder {
            color: #999999 !important;
          }
        }
      }
    }
    & .ant-select {
      &-selector {
        //background-color: unset !important;
        // padding: 0 !important;
      }
      &-selection-item {
        font-size: 15.84px;
      }
      &-selection-placeholder {
        font-size: 15.84px;
      }
    }
  `,

  // inputWithdraw: (isValid = true) => css`
  //   &,
  //   & input {
  //     font-size: 23.76px !important;
  //     font-weight: bold !important;
  //     caret-color: #000;
  //     &::placeholder {
  //       font-size: 15.84px !important;
  //       font-weight: 400 !important;
  //     }
  //     ${!isValid && `color: red !important`}
  //   }
  // `,

  valueFormat: css`
    display: flex;
    align-items: center;
    font-size: 15.84px;
    height: 31px;
    border-bottom: 1px solid #ddd;
    color: #f00;
    gap: 10px;
    padding: 0 15px;
    background-color: #fff;
    div:first-of-type {
      width: 100px;
    }
    div:last-of-type {
      flex: 1;
      display: flex;
      justify-content: space-between;
      & .value {
        font-weight: 700;
      }
    }
  `,

  formSubmit: css`
    padding: 12px 15px;
    button {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px !important;
      font-size: 15.84px;
      color: #fff;
      background-color: #45b5d9 !important;
      &:disabled {
        border: none !important;
        background-color: #aaa !important;
        color: #fff !important;
      }
    }
  `,

  keyboard: css`
    padding: 12px;
    background-color: #f3f3f3;
  `,

  tooltipDesc: css`
    display: flex;
    align-items: center;
    gap: 5px;
    img {
      width: 17px;
      height: 17px;
    }
  `,
};
