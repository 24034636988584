import moment from "moment";
import React, { useEffect, useRef } from "react";
function TopTime() {
  const spanRef = useRef<HTMLSpanElement>(null);
  const time = useRef<NodeJS.Timer | null>(null);
  useEffect(() => {
    if (!spanRef.current) return;
    if (time.current) return;
    time.current = setInterval(() => {
      if (spanRef.current) {
        spanRef.current.textContent = moment().format("DD-MM HH:mm");
      }
    }, 1000);
  }, [spanRef]);
  return <span ref={spanRef}></span>;
}

export default TopTime;
