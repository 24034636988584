import { css, keyframes } from "@emotion/react";

const slideIn = keyframes`
    0%{
        transform: translateY(120vh);
    }
    100%{
        transform: translateY(0);
    }
`;

export const FooterOpenStyle = {
  index: (open: boolean) => css`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 42px;
    z-index: 9;
    height: calc(100vh - 42px);
    display: ${open ? "block" : "none"};

    .overplay {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      z-index: 1;
      opacity: ${open ? "1" : "0"};
    }

    .content {
      background-color: #fff;
      position: absolute;
      width: 100%;
      height: 95px;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      border-bottom: 1px solid #e1e1e2;
      display: flex;
      z-index: 2;
      bottom: 0;
      padding-bottom: 20px;
      overflow: hidden;
      animation: ${slideIn} 0.2s;

      div {
        border-radius: 10px;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        margin: auto 2.5% auto 0;
        box-sizing: border-box;
        text-decoration: none;
        line-height: 1em;
        font-size: 0.8em;
        cursor: pointer;
        color: #fff;
        flex: 2;
        position: relative;
        overflow: hidden;

        span {
          text-align: center;
          width: 100% !important;
        }
      }
      .btn_footer_transfer {
        background-color: #3c92c5;
        flex: 1.5 !important;
        margin-left: 2.5% !important;
      }
      .btn_footer_deposit {
        background-color: #0a9d4c;
        margin-left: 2.5% !important;
        span {
          width: calc(89% - 34px) !important;
        }
        .img_deposit {
          background: url(${`/common/mobile/bottom-tab/icon_footer_deposit.svg`})
            no-repeat center;
          background-size: 100% auto;
          width: 34px;
          height: 34px;
          margin-left: 11%;
        }
      }
      .btn_footer_withdrawal {
        background-color: #f79319;
        span {
          width: calc(89% - 34px) !important;
        }
        .img_withdrawal {
          background: url(${`/common/mobile/bottom-tab/icon_footer_withdrawal.svg`})
            no-repeat center;
          background-size: 100% auto;
          width: 34px;
          height: 34px;
          margin-left: 11%;
        }
      }
    }
  `,
};
