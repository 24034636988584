import { css } from "@emotion/react";

export const MainMenuStyle = {
  index: (language: string) => css`
    display: block;

    .menuArea {
      width: 100%;
      height: 65px;
      position: relative;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.5) 5px 0px 10px;
      display: flex;
      justify-content: center;

      .mainMenu {
        width: 1200px;
        display: flex;

        .logo {
          height: 65px;
          cursor: pointer;
          width: 105px;
          display: flex;
          align-items: center;
          margin-right: 5px;
          text-decoration: none;

          img {
            width: 100%;
            height: auto;
          }
        }

        .menu {
          flex: 1;
          display: flex;

          ul {
            display: table;
            width: 100%;

            li {
              display: table-cell;
              text-align: center;
              padding: 0 3px;
              &.hover {
                a {
                  border-bottom: 4px solid #3787be;
                  color: #0076c6;
                }
              }

              &.line {
                span {
                  float: left;
                  line-height: 65px;
                  color: #dadada;
                  font-size: 18px;
                }
              }

              a {
                text-decoration: none;
              }

              .menuA,
              .menuAOn,
              .menuA_colorO {
                font-size: 14px;
                float: left;
                width: 100%;
                min-width: 60px;
                white-space: nowrap;
                color: #555;
                text-align: center;
                cursor: pointer;
                box-sizing: border-box;
                height: 65px;
                line-height: 65px;
                font-weight: bold;
                text-decoration: none;
              }

              .menuA_colorO {
                color: #ac35de;
              }

              .menuA {
                position: relative;

                &:hover {
                  border-bottom: 4px solid #3787be;
                  color: #0076c6;
                }

                img {
                  display: inline-block;
                  vertical-align: middle;
                }

                .iconActivity {
                  width: auto;
                  height: auto;
                  max-width: 30px;
                  max-height: 38px;
                  margin-right: 2px;
                  margin-top: -5px;
                }

                .icon_phone {
                  background: url(${`common/desktop/menu/icon_indexImg.png`})
                    no-repeat left -819px;
                  margin: 0px 3px 0 0;
                  width: 12px;
                  height: 20px;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  `,
};
