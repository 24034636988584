/** @jsxImportSource @emotion/react */

import { PATH } from "constants/path";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { AnnouncementHeaderStyle } from "./index.style";

const AnnouncementHeader = () => {
  const { t } = useTranslation(["message"]);

  return (
    <div css={AnnouncementHeaderStyle.wrapper}>
      <header css={AnnouncementHeaderStyle.self}>
        <h1 css={AnnouncementHeaderStyle.title}> {t("informationCenter")}</h1>
        <ul css={AnnouncementHeaderStyle.tabs}>
          <li css={AnnouncementHeaderStyle.tabItem}>
            <NavLink to={PATH.PersonalMessage}> {t("personalMessage")}</NavLink>
            <span></span>
          </li>
          <li css={AnnouncementHeaderStyle.line}></li>
          <li css={AnnouncementHeaderStyle.tabItem}>
            <NavLink to={PATH.Annoucement}>{t("notification")}</NavLink>
            <span></span>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default AnnouncementHeader;
