/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";

import { FooterStyle } from "./index.style";

export const Footer = () => {
  const { i18n } = useTranslation();
  return (
    <div css={FooterStyle.index(i18n.language)}>
      <div className="brandImg" />
    </div>
  );
};
