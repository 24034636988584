import { css } from "@emotion/react";

export const DepositExampleStyle = {
  self: css`
    width: 100%;
  `,

  title: css`
    height: 35px;
    padding-left: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #527fbd;
    line-height: 35px;
    background-color: #ebebeb;
  `,

  content: css`
    //
  `,
};
