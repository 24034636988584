import { PATH } from "constants/path";

export interface BottomItemType {
  name: string;
  icon: string;
  path: string;
  center: boolean;
  className: string;
  isLogin: boolean;
  isIncluded?: boolean;
}
export const listBottomTabs: BottomItemType[] = [
  {
    name: "promotion",
    icon: "/mobile/bottom-tab/icon_footer_gift.png",
    path: PATH.promotion,
    center: false,
    className: "btn_footer_gift",
    isLogin: false,
  },
  {
    name: "home",
    icon: "/mobile/bottom-tab/icon_footer_home.svg",
    path: PATH.Home,
    center: false,
    className: "btn_footer_home",
    isLogin: false,
  },
  {
    name: "support",
    icon: "/mobile/bottom-tab/icon_footer_service.svg",
    path: PATH.HelpCenter.ServiceCenter,
    center: false,
    className: "btn_footer_serv",
    isLogin: false,
  },
  {
    name: "deposit-withdraw",
    icon: "/mobile/bottom-tab/icon_footer_DW.svg",
    path: "",
    center: true,
    className: "btn_footer_DW",
    isLogin: true,
  },
  {
    name: "transaction",
    icon: "/mobile/bottom-tab/icon_footer_tradeRec.svg",
    path: PATH.User.Transaction,
    center: false,
    className: "btn_footer_tradeRec",
    isLogin: true,
  },
  {
    name: "profile",
    icon: "/mobile/bottom-tab/icon_footer_member.svg",
    path: PATH.User.index,
    center: false,
    className: "btn_footer_menu",
    isLogin: true,
    isIncluded: true,
  },
];
