import { ContextAction } from "types";

import { AppState, ContextActionType } from "./app.context";

export const reducer = (
  state: AppState,
  action: ContextAction<ContextActionType, AppState>,
): AppState => {
  switch (action.type) {
    case "OPENMODAL":
      return {
        ...state,
        openModal: action.payload.openModal,
        code: action.payload.code,
      };
    case "CHANGELANGUAGE":
      return {
        ...state,
        language: action.payload.language,
      };
    default:
      return state;
  }
};
