/** @jsxImportSource @emotion/react */

import { dataKeyboard1, dataKeyboard2, keyBoardType } from "./data-keyboard";
import { KeyboardNumberStyle } from "./index.style";

type Props = {
  onClick: (val: string) => void;
  value: string;
};

function KeyboardNumber({ onClick, value }: Props) {
  const onClickItem = (item: keyBoardType) => {
    if (item.value === "DELETE") {
      if (value.length > 0) {
        onClick(value.slice(0, -1));
      } else {
        onClick("");
      }
      return;
    }
    if (value !== "") {
      onClick(value + item.value);
      return;
    }
    onClick(item.value as string);
  };

  return (
    <div css={KeyboardNumberStyle.index}>
      <div className="grid">
        {dataKeyboard1.map((item, index) => {
          return (
            <div
              key={index}
              className="item"
              onClick={() => onClickItem(item)}
              css={KeyboardNumberStyle.item(`/common/${item.icon}`)}
            ></div>
          );
        })}
      </div>
      <div className="grid">
        {dataKeyboard2.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => onClickItem(item)}
              css={KeyboardNumberStyle.item(`/common/${item.icon}`)}
              className="item"
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default KeyboardNumber;
