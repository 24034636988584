import { css } from "@emotion/react";

export const BanksCollapseStyle = {
  self: css`
    & .ant-collapse {
      border-radius: 0;
      border: none;
      &-header {
        padding: 0 !important;
        background-color: unset !important;
      }
      &-item {
        border-bottom: 1px solid #ddd;
        border-radius: 0 !important;
      }
      &-content {
        border-radius: 0 !important;
        &-box {
          padding: 0 !important;
        }
      }
    }
  `,

  collapseLabel: css`
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    background-color: #fff;
    justify-content: space-between;
  `,

  // bankLogo: css`
  //   width: 122px;
  //   height: 37px;
  //   border: 1px solid #ddd;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin: 0 15px;
  //   img {
  //     max-width: 100px;
  //   }
  // `,

  bankInfo: (isCollapse = false) => css`
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;

    & .value {
      color: #2782d7;
      margin: 0 30px 0 10px;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      fill: #c6c6ca;
      font-size: 18px;
      ${isCollapse &&
      `
        transform: rotate(180deg)
    `}
    }
  `,

  collapseContent: css`
    padding: 15px;
    background-color: #eee;
  `,

  bankOptions: css`
    &.ant-radio-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      & .ant-radio-button-wrapper {
        width: 100%;
        height: 50px;
        border-radius: 2px;
        background-color: #fff !important;
        border: 1px solid #ddd;
        color: #000 !important;
        &::before {
          display: none;
        }
        &-checked {
          background-color: #daf1ff !important;
          border: 1px solid #2782d7;
          color: #2782d7 !important;
          div {
            display: none !important;
          }
        }
      }
    }
  `,

  bankItem: css`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    padding: 5px 15px;
    justify-content: space-between;

    & .bank-title {
      &-wrapper {
        display: inline-block;
      }
      color: #1f2326;
      font-weight: 500;
      margin-right: 20px;
    }
  `,

  bankContent: css`
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    & .label {
      display: inline-block;
    }
    & .value {
      max-width: 130px;
      color: #2782d7;
      margin: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & .bank-title-wrapper {
      min-width: 105px;
    }
  `,

  btnAddBank: css`
    width: 100%;
    height: 50px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #ddd !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #666 !important;
    gap: 10px;
    div {
      display: none !important;
    }
    img {
      width: 24px;
      height: 24px;
    }
  `,
};
