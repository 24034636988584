export const CATEGORY_CODE = {
  SPORT: "SB",
  LIVE_CASINO: "LC",
  GAMES: "SL",
  LOTTERY: "LT",
  E_SPORT: "ES",
  FISHING: "FH",
  CHESS_GAME: "BG",
  COCK_FIGHTING: "CF",
};

export const listType = [
  CATEGORY_CODE.CHESS_GAME,
  CATEGORY_CODE.E_SPORT,
  CATEGORY_CODE.FISHING,
  CATEGORY_CODE.GAMES,
  CATEGORY_CODE.LIVE_CASINO,
  CATEGORY_CODE.LOTTERY,
  CATEGORY_CODE.SPORT,
  CATEGORY_CODE.COCK_FIGHTING,
];
