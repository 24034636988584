import { css, Theme } from "@emotion/react";

export const PersonalMessageHeaderStyle = {
  wrapper: css`
    height: 60px;
    background-color: #0c5d91;
  `,

  self: css`
    width: 800px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 25px;
  `,

  left: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    height: 100%;
  `,

  title: ({ colors }: Theme) => css`
    color: ${colors.white};
    font-size: 18px;
    font-weight: bold;
  `,

  tabs: css`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
  `,

  tabItem: ({ colors }: Theme) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6caed8;
    height: 100%;
    position: relative;
    a {
      font-size: 13px;
      color: ${colors.white};
      text-decoration: none;
      font-size: 13px;
      margin-top: 15px;
    }
    span {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #e5e5e5;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin: 0 auto;
      display: none;
    }
    & .active {
      margin-top: 0;
    }
    & .active ~ span {
      display: block;
    }
  `,

  line: css`
    background-color: #3480b1 !important;
    width: 1px;
    height: 15px;
  `,

  btnMessage: css`
    width: 130px;
    height: 27px;
    border-radius: 0;
    background-color: #00bbb4 !important;
    font-size: 13.33px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  editIcon: css`
    fill: #fff;
  `,
};
