/** @jsxImportSource @emotion/react */

import { ReactComponent as EmptyIcon } from "assets/desktop/icons/empty.svg";
import { useTranslation } from "react-i18next";

import { EmptyComponentStyle } from "./index.style";

const EmptyComponent = () => {
  const { t } = useTranslation(["common"]);

  return (
    <div css={EmptyComponentStyle.self}>
      <EmptyIcon css={EmptyComponentStyle.emptyIcon} />
      <p css={EmptyComponentStyle.emptyMsg}>{t("noData")}</p>
    </div>
  );
};

export default EmptyComponent;
