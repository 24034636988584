import { css } from "@emotion/react";

export const AnnouncementPageStyle = {
  self: css`
    /*  */
  `,

  content: css`
    width: 800px;
    margin: 5px auto;
    padding: 0 5px;
  `,

  tableWrapper: css`
    padding: 10px 10px 0 10px;
    background-color: #d5d5d5;
    height: 529px;
  `,

  table: css`
    & .ant-table {
      border-radius: 0;
      &-thead {
        background-color: #4a80a3;
        tr,
        th,
        td {
          background-color: #4a80a3 !important;
          border-radius: 0 !important;
          color: #fff !important;
          text-align: center !important;
          font-size: 14px;
          font-weight: 400 !important;
          height: 42px;
          padding: 0 !important;
          border-color: #d5d5d5 !important;
        }
      }
      &-tbody {
        tr:not(.ant-table-expanded-row),
        th,
        td {
          height: 42px;
          padding: 0 15px !important;
          border-color: #d5d5d5 !important;
          cursor: pointer;
        }

        & .ant-table-row {
          & .ant-table-cell {
            background-color: transparent !important;
          }
          &:has(+ .active) {
            background-color: #78a7c6 !important;
            & .ant-table-cell {
              color: #fff;
            }
          }
          &:hover {
            background-color: #78a7c6 !important;
            & .ant-table-cell {
              color: #fff;
            }
          }
        }
        & .active {
          height: 0;
          & .ant-table-cell {
            padding: 0 !important;
          }
        }
      }
      &-container {
        border-top: none !important;
      }
    }
  `,

  headTitleWrapper: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `,

  headTitle: css`
    flex: 1;
  `,

  downIcon: (open = false) => css`
    fill: #dbdbdb;
    transition: all ease 0.25s;
    ${open && "fill: #FDFDFD; transform: rotate(-180deg)"};
  `,

  expandContent: () => css`
    padding: 25px 15px;
    background-color: #e4f4ff;
  `,

  empty: css`
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
