import { css } from "@emotion/react";

export const BalanceStyle = {
  index: (open: boolean, color: string, language: string) => css`
    .icon_inforMoney {
      color: ${color};
      cursor: pointer;
      position: relative;
      /* padding-right: 15px; */
      padding-left: 13px;

      &::before {
        content: "$";
        position: absolute;
        left: 0;
        top: 0px;
        width: 10px;
        text-align: right;
      }

      /* &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url(${`/common/mobile/header/icon_arrowGray.svg`})
          no-repeat center;
        background-size: 100% auto;
        width: 10px;
        height: 10px;
        transform: rotate(${open ? "180deg" : "0deg"});
      } */
    }
  `,
};
