/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, message, Tooltip } from "antd";
import { UserApi } from "api/user";
import { withdrawApi } from "api/withdraw";
import { WithdrawalSubMissionForm } from "api/withdraw/type";
import IconPrompt from "assets/desktop/images/icon_prompt.png";
import { ReactComponent as IconInputClear } from "assets/mobile/icons/input_clear.svg";
import HelmetCustom from "components/common/HelmetCustom";
import InputCustom from "components/common/InputCustom";
import KeyboardNumber from "components/common/KeyboardNumber";
import Loading from "components/common/Loading";
import Balance from "components/mobile/Balance";
import HeaderPage from "components/mobile/HeaderPage";
import { QueryKeys } from "constants/queryKeys";
import { useWithdrawContext } from "contexts/withdraw/withdraw.context";
import { formatCurrency } from "helpers/formatCurrency";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BalanceType } from "types/user";
import * as yup from "yup";

import BanksCollapse from "./BanksCollapse";
import { MWithdrawPageStyle } from "./index.style";
import WithdrawAttention from "./WithdrawAttention";

interface FormData {
  withdrawPoint: number;
}

const MWithdrawPage = () => {
  const { t } = useTranslation(["payment", "common", "auth"]);
  const { state } = useWithdrawContext();
  const [messageApi, contextHolder] = message.useMessage();

  const [showKeyboardWithdrawPoint, setShowKeyboardWithdrawPoint] =
    useState<boolean>(false);

  const { data: username } = useQuery<string>([QueryKeys.username]);

  const { data: dataWithdrawSetting, isLoading: isWithdrawSettingLoading } =
    useQuery({
      queryKey: [QueryKeys.WithdrawForm],
      queryFn: () => withdrawApi.getWithdrawalForm(username as string),
      enabled: !!username,
    });
  const { refetch: refetchBalance } = useQuery<BalanceType>(
    [QueryKeys.GetBalanceDV],
    {
      queryFn: () =>
        UserApi.getMemberBalance({
          username: username as string,
        }),
      enabled: !!username,
      onSuccess: (data) => {
        if (data.total) {
          localStorage.setItem("totalBalance", data.total);
        }
      },
      refetchOnWindowFocus: false,
    },
  );

  const schema = yup
    .object({
      withdrawPoint: yup
        .number()
        .min(dataWithdrawSetting?.setting.min_withdrawal || 0)
        .max(dataWithdrawSetting?.setting.max_withdrawal || 0)
        .required(),
    })
    .required();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: [QueryKeys.WithdrawSubmission],
    mutationFn: (payload: WithdrawalSubMissionForm) =>
      withdrawApi.withdrawalSubmission(payload.username, payload),
    onSuccess(data) {
      messageApi.open({
        type: "success",
        content: data.msg,
      });
      reset({
        withdrawPoint: undefined,
      });
      clearErrors();
      refetchBalance();
    },
    onError(error: any) {
      messageApi.open({
        type: "error",
        content: error.message,
      });
    },
  });

  const onSubmit = (form: FormData) => {
    if (
      !dataWithdrawSetting?.player_bank.length &&
      !state.playerBankSelected?.player_bank_id
    ) {
      messageApi.open({
        type: "error",
        content: t("validation.bankAccount"),
      });
      return;
    }

    const payload: WithdrawalSubMissionForm = {
      username: username || "",
      currency_id: dataWithdrawSetting?.currencies[0].currency_id || "",
      player_bank_id: state.playerBankSelected?.player_bank_id || "",
      amount: form.withdrawPoint,
    };
    mutate(payload);
  };

  return (
    <div css={MWithdrawPageStyle.self}>
      <HeaderPage
        title={t("withdrawal.mobile.self")}
        rightComponent={<Balance />}
      />
      <HelmetCustom title={t("withdrawal.mobile.self")} />
      {isWithdrawSettingLoading ? (
        <Loading height={500} center />
      ) : (
        <>
          <div css={MWithdrawPageStyle.bankTitle}>{t("bank")}</div>
          <form
            css={MWithdrawPageStyle.formWrapper}
            onSubmit={handleSubmit(onSubmit)}
          >
            <BanksCollapse />
            <div css={MWithdrawPageStyle.formGroup}>
              <label>{t("withdrawal.point")}</label>
              <Tooltip
                overlayInnerStyle={{ padding: "8px" }}
                title={
                  <div css={MWithdrawPageStyle.tooltipDesc}>
                    <img loading="lazy" src={IconPrompt} alt="" />
                    <span style={{ color: "red" }}>
                      {`${t("withdrawal.point")} ${
                        dataWithdrawSetting?.setting.min_withdrawal || 0
                      } ~ ${dataWithdrawSetting?.setting.max_withdrawal || 0}`}
                    </span>
                  </div>
                }
                color="#fff"
                open={!!errors.withdrawPoint && !!watch("withdrawPoint")}
                placement="bottomLeft"
              >
                <InputCustom
                  id="withdrawPoint"
                  type="number"
                  control={control}
                  name="withdrawPoint"
                  placeholder={`${
                    dataWithdrawSetting?.setting.min_withdrawal || 0
                  } ~ ${dataWithdrawSetting?.setting.max_withdrawal || 0}`}
                  // onInput={(e: any) => {
                  //   e.target.value = e.target.value.slice(0, 10);
                  // }}
                  onClick={() => setShowKeyboardWithdrawPoint((prev) => !prev)}
                  suffix={
                    !!watch("withdrawPoint") && (
                      <IconInputClear
                        onClick={() =>
                          setValue("withdrawPoint", undefined as any, {
                            shouldValidate: true,
                          })
                        }
                        style={{ width: "20px" }}
                      />
                    )
                  }
                  disabled={isLoading}
                  allowClear
                  readOnly
                />
              </Tooltip>
            </div>

            <div css={MWithdrawPageStyle.valueFormat}>
              <div>{t("withdrawal.actual")}</div>
              <div>
                <span className="value">
                  {formatCurrency(+watch("withdrawPoint") * 1000 || 0)}
                </span>
                <span>VNĐ</span>
              </div>
            </div>

            {showKeyboardWithdrawPoint && (
              <div css={MWithdrawPageStyle.keyboard}>
                <KeyboardNumber
                  value={watch("withdrawPoint")?.toString().slice(0, 10) || ""}
                  onClick={(value) =>
                    setValue("withdrawPoint", +value, {
                      shouldValidate: true,
                    })
                  }
                />
              </div>
            )}

            <div css={MWithdrawPageStyle.formSubmit}>
              <Button
                type="primary"
                htmlType="submit"
                block
                disabled={!isValid}
                loading={isLoading}
              >
                {t("common:confirm")}
              </Button>
            </div>
          </form>
          <WithdrawAttention />
        </>
      )}
      {contextHolder}
    </div>
  );
};

export default MWithdrawPage;
