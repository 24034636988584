import loadable from "@loadable/component";
import App from "App";
import { PATH } from "constants/path";
import { DepositContextProvider } from "contexts/deposit/deposit.context";
import { WithdrawContextProvider } from "contexts/withdraw/withdraw.context";
import { DefaultLayout } from "layouts/mobile/DefaultLayout";
import ReferCode from "pages/common/ReferCode";
import MWithdrawPage from "pages/mobile/MWithdrawPage";
import { RouteObject } from "react-router-dom";

import ProtectedRoute from "./protected";

const HomePage = loadable(() => import("pages/mobile/HomePage"));
const Promotion = loadable(() => import("pages/mobile/Promotion"));
const PromotionDetail = loadable(
  () => import("pages/mobile/Promotion/PromotionDetail"),
);
const ServiceCenter = loadable(() => import("pages/mobile/ServiceCenter"));
const UpdateMemberDetail = loadable(
  () => import("pages/mobile/UpdateMemberDetail"),
);
const ChangePassword = loadable(() => import("pages/mobile/ChangePassword"));
const UserPage = loadable(() => import("pages/mobile/UserPage"));
const CustomerTeach = loadable(() => import("pages/mobile/CustomerTeach"));
const AboutUs = loadable(() => import("pages/mobile/AboutUs"));
const HelpCenter = loadable(() => import("pages/mobile/HelpCenter"));
const SubGame = loadable(() => import("pages/mobile/SubGame"));
const TransactionHistory = loadable(
  () => import("pages/mobile/TransactionHistory"),
);
const MAddMemberBank = loadable(() => import("pages/mobile/MAddMemberBank"));

const Register = loadable(() => import("pages/mobile/Register"));
const MRulesPage = loadable(() => import("pages/mobile/MRulesPage"));
const MMemberPromotion = loadable(
  () => import("pages/mobile/MMemberPromotion"),
);
const Announcement = loadable(() => import("pages/mobile/Announcement"));
const MDepositPage = loadable(() => import("pages/mobile/MDepositPage"));
const MDepositExamplePage = loadable(
  () => import("pages/mobile/MDepositExamplePage"),
);
export const MobileRoutes: RouteObject[] = [
  {
    path: PATH.ReferCode,
    element: <ReferCode />,
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: PATH.Home,
            element: <HomePage />,
          },
          {
            path: PATH.promotion,
            element: <Promotion />,
          },
          {
            path: PATH.promotion + "/:id",
            element: <PromotionDetail />,
          },
          {
            path: PATH.HelpCenter.ServiceCenter,
            element: <ServiceCenter />,
          },
          {
            element: <ProtectedRoute />,
            children: [
              {
                path: PATH.User.index,
                element: <UserPage />,
              },
              {
                path: PATH.Annoucement,
                element: <Announcement />,
              },
              {
                path: PATH.User.Transaction,
                element: <TransactionHistory />,
              },
              {
                path: PATH.User.Withdraw,
                element: (
                  <WithdrawContextProvider>
                    <MWithdrawPage />
                  </WithdrawContextProvider>
                ),
              },
              {
                path: PATH.User.AddMemberBank,
                element: <MAddMemberBank />,
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: PATH.User.Profile,
            element: <UpdateMemberDetail />,
          },
          {
            path: PATH.User.ChangePassword,
            element: <ChangePassword />,
          },
          {
            path: PATH.User.MemberPromotion,
            element: <MMemberPromotion />,
          },
          {
            path: PATH.User.Deposit,
            element: (
              <DepositContextProvider>
                <MDepositPage />
              </DepositContextProvider>
            ),
          },
          {
            path: PATH.User.DepositExample,
            element: <MDepositExamplePage />,
          },
        ],
      },
      {
        path: PATH.Register,
        element: <Register />,
      },
      {
        path: PATH.CustomerTeach,
        element: <CustomerTeach />,
      },
      {
        path: PATH.AboutUS.index,
        element: <AboutUs />,
      },
      {
        path: PATH.HelpCenter.index,
        element: <HelpCenter />,
      },
      {
        path: PATH.SubGame + "/:provider",
        element: <SubGame />,
      },
      {
        path: PATH.Rules,
        element: <MRulesPage />,
      },
    ],
  },
];
