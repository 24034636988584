import { css } from "@emotion/react";

export const ChangeLanguageStyle = {
  overplay: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 8;
  `,
  index: css`
    display: flex;
    align-items: center;
    position: relative;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }

    .popup {
      width: 120px;
      position: fixed;
      z-index: 9;
      background-color: #fff;
      top: 40px;
      right: 12px;
      overflow: hidden;
      border-radius: 4px;
      display: none;
      box-shadow:
        rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

      &.open {
        display: block;
      }

      .item {
        padding: 8px 10px;
        display: flex;
        align-items: center;
        width: 100%;

        &.active {
          background: #e7f1fb;
        }

        span {
          margin-left: 6px;
          font-size: 0.9em;
        }
      }
    }
  `,
};
