/* eslint-disable */
// import data_icon from "assets/desktop/images/deposit/data_icon.png";
//
import atm01 from "assets/desktop/images/deposit/example/example_atm01.png";
import atm02 from "assets/desktop/images/deposit/example/example_atm02.png";
import counterBank01 from "assets/desktop/images/deposit/example/example_counterBank01.png";
import counterBank02 from "assets/desktop/images/deposit/example/example_counterBank02.png";
//
import EBankCard01 from "assets/desktop/images/deposit/example/example_e-bankcard01.png";
import EBankCard02 from "assets/desktop/images/deposit/example/example_e-bankcard02.png";
import EBankCard03 from "assets/desktop/images/deposit/example/example_e-bankcard03.jpeg";
import EBankCard04 from "assets/desktop/images/deposit/example/example_e-bankcard04.jpeg";
import EBankCard05 from "assets/desktop/images/deposit/example/example_e-bankcard05.jpeg";
import EBankCard06 from "assets/desktop/images/deposit/example/example_e-bankcard06.jpeg";
import EBankCard07 from "assets/desktop/images/deposit/example/example_e-bankcard07.jpeg";
import EBankCard08 from "assets/desktop/images/deposit/example/example_e-bankcard08.jpeg";
import EBankCard09 from "assets/desktop/images/deposit/example/example_e-bankcard09.jpeg";
// //
import MomoQr01 from "assets/desktop/images/deposit/example/example_momoqr01.png";
import MomoQr02 from "assets/desktop/images/deposit/example/example_momoqr02.png";
import MomoQr03 from "assets/desktop/images/deposit/example/example_momoqr03.jpeg";
import MomoQr04 from "assets/desktop/images/deposit/example/example_momoqr04.jpeg";
// //
import ExampleOnline01 from "assets/desktop/images/deposit/example/example_onlineBank01.png";
import ExampleOnline02 from "assets/desktop/images/deposit/example/example_onlineBank02.png";
// //
import ExampleQrPayment01 from "assets/desktop/images/deposit/example/example_qrpayment01.png";
import ExampleQrPayment02 from "assets/desktop/images/deposit/example/example_qrpayment02.jpg";
import ExampleQrPayment04 from "assets/desktop/images/deposit/example/example_qrpayment04.jpeg";
import ExampleQrPayment05 from "assets/desktop/images/deposit/example/example_qrpayment05.jpeg";
// //
import ViettelMoney01 from "assets/desktop/images/deposit/example/example_viettelMoney01.png";
import ViettelMoney02 from "assets/desktop/images/deposit/example/example_viettelMoney02.jpg";
import ViettelMoney03 from "assets/desktop/images/deposit/example/example_viettelMoney03.jpeg";
import ViettelMoney04 from "assets/desktop/images/deposit/example/example_viettelMoney04.jpeg";
import ZaloQr01 from "assets/desktop/images/deposit/example/example_zaloqr01.png";
import ZaloQr02 from "assets/desktop/images/deposit/example/example_zaloqr02.png";
import ZaloQr03 from "assets/desktop/images/deposit/example/example_zaloqr03.jpeg";
import ZaloQr04 from "assets/desktop/images/deposit/example/example_zaloqr04.jpeg";
import AtmTransferImg from "assets/desktop/images/deposit/icon_AtmTransfer.png";
import GotoBank from "assets/desktop/images/deposit/icon_goToBank.png";
// //
import USDTQr01 from "assets/desktop/images/deposit/example/example_usdtqr01.png";
import USDTQr02 from "assets/desktop/images/deposit/example/example_usdtqr02.png";
import icon_momoPay from "assets/desktop/images/deposit/icon_momoPay.svg";
import icon_viettelpay from "assets/desktop/images/deposit/icon_viettelpay.png";
import icon_webBank_1 from "assets/desktop/images/deposit/icon_webBank_1.png";
import icon_zaloPay from "assets/desktop/images/deposit/icon_zaloPay.svg";
import img_ThirdPart from "assets/desktop/images/deposit/img_ThirdPart.png";
import icon_usdt from "assets/desktop/images/deposit/usdt.png";
import { DepositMethod } from "enums/deposit";
import React, { CSSProperties } from "react";
import { Translation } from "react-i18next";

export interface DepositExample {
  texts: string[] | React.ReactNode[];
  content: React.ReactNode;
}

export interface DepositPaymentOption {
  id: DepositMethod;
  name: React.ReactNode;
  title?: string | React.ReactNode;
  image: string;
  minDeposit: number;
  maxDeposit: number;
  imageStyle?: CSSProperties;
  examples?: DepositExample[];
  isStepMode?: boolean;
  isLanguage?: boolean;
  isHide?: boolean;
}

export const DEPOSIT_PAYMENT_OPTIONS: DepositPaymentOption[] = [
  {
    id: DepositMethod.EBanking,
    name: (
      <Translation ns={["payment"]}>
        {(t) => <>{t("onlinePayment", { ns: "payment" })}</>}
      </Translation>
    ),
    title: "E-banking",
    image: "/desktop/deposit/payment_online.png",
    minDeposit: 100,
    maxDeposit: 50000,
    isLanguage: true,
    imageStyle: { width: "85%" },
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.chooseMethod", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.chooseBank", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.choosePromotion", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.enterDepositPoints", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ExampleOnline01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.selectBankDeposit", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ExampleOnline02}
            alt=""
          />
        ),
      },
      // {
      //   texts: [
      //     <Translation ns={["payment"]}>
      //       {(t) => (
      //         <>{t("deposit.example.redirectToTheBank", { ns: "payment" })}</>
      //       )}
      //     </Translation>,
      //   ],
      //   content: <img style={{objectFit:"contain",width:"100%",maxHeight:"65vh"}} src={ExampleOnline03} alt="" />,
      // },
    ],
    isStepMode: true,
  },
  {
    id: DepositMethod.ATM,
    name: (
      <Translation ns={["payment"]}>
        {(t) => <>{t("deposit.example.transferAtAtm", { ns: "payment" })}</>}
      </Translation>
    ),
    image: AtmTransferImg,
    minDeposit: 100,
    maxDeposit: 50000,
    imageStyle: { width: "94px" },
    title: (
      <Translation ns={["payment"]}>
        {(t) => (
          <>{t("deposit.example.atmTransferProcess", { ns: "payment" })}</>
        )}
      </Translation>
    ),
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.chooseBank", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.choosePromotion", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.enterDepositPoints", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={atm01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.selectBankDeposit", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={atm02}
            alt=""
          />
        ),
      },
    ],
    isStepMode: true,
  },
  {
    id: DepositMethod.Online,
    name: (
      <Translation ns={["payment"]}>
        {(t) => (
          <>
            {t("deposit.example.electronicBanking", {
              ns: "payment",
            })}
          </>
        )}
      </Translation>
    ),
    title: "EbankCard",
    image: icon_webBank_1,
    minDeposit: 100,
    maxDeposit: 50000,
    imageStyle: { width: "75px" },
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.selectExternalTransfer", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.chooseBank", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.choosePromotion", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.enterDepositPoints", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.copyCardNumber", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard02}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.enterUsername", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.enterPassword", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.enterVerificationCode", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.pressLogin", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard03}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.select24/7", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard04}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.selectFastTransfer", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.clickCardTransfer", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.enterRecipientCard", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.enterAmount", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.pressContinue", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard05}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.confirmTransferInfomation", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.enterVerificationCode", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.pressConfirm", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard06}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.enterOtp", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.pressVerify", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard07}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.transactionSuccessful", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard08}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.selectBankNumber", {
                  ns: "payment",
                })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={EBankCard09}
            alt=""
          />
        ),
      },
    ],
    isStepMode: true,
  },
  {
    id: DepositMethod.AtTheCounter,
    name: (
      <Translation ns={["payment"]}>
        {(t) => <>{t("counterService", { ns: "payment" })}</>}
      </Translation>
    ),
    title: (
      <Translation ns={["payment"]}>
        {(t) => (
          <>{t("deposit.example.inPersonTransferProcess", { ns: "payment" })}</>
        )}
      </Translation>
    ),
    image: GotoBank,
    minDeposit: 100,
    maxDeposit: 50000,
    imageStyle: { width: "85%" },
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.chooseMethod", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.chooseBank", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.choosePromotion", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.enterDepositPoints", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={counterBank01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.selectBankDeposit", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={counterBank02}
            alt=""
          />
        ),
      },
    ],
    isStepMode: true,
    isHide: true,
  },
  {
    id: DepositMethod.QRPay,
    name: "QRcode",
    title: "QRpay",
    image: img_ThirdPart,
    minDeposit: 100,
    maxDeposit: 50000,
    imageStyle: { width: "94px" },
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.chooseMethod", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.chooseBank", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.choosePromotion", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.enterDepositPoints", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ExampleQrPayment01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.selectBankDeposit", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ExampleQrPayment02}
            alt=""
          />
        ),
      },
      // {
      //   texts: [
      //     <Translation ns={["payment"]}>
      //       {(t) => (
      //         <>{t("deposit.example.displayQrCode", { ns: "payment" })}</>
      //       )}
      //     </Translation>,
      //   ],
      //   content: <img style={{objectFit:"contain",width:"100%",maxHeight:"65vh"}} src={ExampleQrPayment03} alt="" />,
      // },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.selectQrService", { ns: "payment" })}</>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ExampleQrPayment04}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.scanQR", { ns: "payment" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ExampleQrPayment05}
            alt=""
          />
        ),
      },
    ],
    isStepMode: true,
  },
  {
    id: DepositMethod.Momo,
    name: "MoMo",
    title: (
      <Translation ns={["payment"]}>
        {(t) => <>{t("deposit.example.momoScanCode", { ns: "payment" })}</>}
      </Translation>
    ),
    image: icon_momoPay,
    minDeposit: 100,
    maxDeposit: 10000,
    imageStyle: { width: "80px" },
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.chooseMethod", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.choosePromotion", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.enterDepositPoints", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={MomoQr01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.displayQrCode", { ns: "payment" })}</>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={MomoQr02}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.selectScanCode", { ns: "payment" })}</>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={MomoQr03}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.scanQR", { ns: "payment" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={MomoQr04}
            alt=""
          />
        ),
      },
    ],
    isStepMode: true,
  },
  {
    id: DepositMethod.ZaloPay,
    name: "ZaloPay",
    title: (
      <Translation ns={["payment"]}>
        {(t) => <>{t("deposit.example.zaloPayScanCode", { ns: "payment" })}</>}
      </Translation>
    ),
    image: icon_zaloPay,
    minDeposit: 100,
    maxDeposit: 10000,
    imageStyle: { width: "80px" },
    isStepMode: true,
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.choosePromotion", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.enterDepositPoints", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ZaloQr01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.displayQrCode", { ns: "payment" })}</>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ZaloQr02}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.selectScanCode", { ns: "payment" })}</>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ZaloQr03}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.scanQR", { ns: "payment" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ZaloQr04}
            alt=""
          />
        ),
      },
    ],
  },

  // {
  //   id: DepositMethod.Crypto,
  //   name: "Tiền mã hoá",
  //   image: data_icon,
  //   minDeposit: 200,
  //   maxDeposit: 20000,
  //   imageStyle: { width: "94px" },
  // },
  {
    id: DepositMethod.ViettelPay,
    name: "Viettel Money",
    title: "Viettel money",
    image: icon_viettelpay,
    minDeposit: 100,
    maxDeposit: 10000,
    imageStyle: { width: "80%" },
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.choosePromotion", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.enterDepositPoints", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ViettelMoney01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.displayQrCode", { ns: "payment" })}</>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ViettelMoney02}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>
                {t("deposit.example.selectQrServiceViettel", { ns: "payment" })}
              </>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ViettelMoney03}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.scanQR", { ns: "payment" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={ViettelMoney04}
            alt=""
          />
        ),
      },
    ],
    isStepMode: true,
  },
  {
    id: DepositMethod.USDT,
    name: "USDT",
    title: (
      <Translation ns={["payment"]}>
        {(t) => <>{t("deposit.example.USDT", { ns: "payment" })}</>}
      </Translation>
    ),
    image: icon_usdt,
    minDeposit: 100,
    maxDeposit: 10000,
    imageStyle: { width: "80px" },
    isStepMode: true,
    examples: [
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.choosePromotion", { ns: "payment" })}</>
            )}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.inputAdd", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["payment"]}>
            {(t) => <>{t("deposit.example.inputUsdt", { ns: "payment" })}</>}
          </Translation>,
          <Translation ns={["common"]}>
            {(t) => <>{t("clickConfirm", { ns: "common" })}</>}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={USDTQr01}
            alt=""
          />
        ),
      },
      {
        texts: [
          <Translation ns={["payment"]}>
            {(t) => (
              <>{t("deposit.example.displayQrCode", { ns: "payment" })}</>
            )}
          </Translation>,
        ],
        content: (
          <img
            style={{ objectFit: "contain", width: "100%", maxHeight: "65vh" }}
            src={USDTQr02}
            alt=""
          />
        ),
      },
    ],
  },
];
