import { SubGameType } from "types/game";

import { CATEGORY_CODE } from "./type";

export const listLottery: SubGameType[] = [
  {
    game_code: "",
    name: "WG",
    provider_code: "WG",
    provider_name: "WG",
    type: CATEGORY_CODE.LOTTERY,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/lottery/bg_topLoto.png") no-repeat;
    background-position: -250px top !important;
    `,
    icon: "/desktop/lottery/WG_logo.png",
  },
  {
    game_code: "",
    name: "BBIN",
    provider_code: "BBIN",
    provider_name: "BBIN",
    type: CATEGORY_CODE.LOTTERY,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/lottery/bg_topLoto.png") no-repeat;
    background-position: left top !important;
    `,
    icon: "/desktop/lottery/logo_BBIN.png",
  },
  {
    game_code: "",
    name: "LOTTOPH",
    provider_code: "LTPH",
    provider_name: "LOTTOPH",
    type: CATEGORY_CODE.LOTTERY,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/lottery/bg_topLoto.png") no-repeat;
    background-position: -500px top !important;
    `,
    icon: "/desktop/lottery/logo_LTPH.png",
  },
];
