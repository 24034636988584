/** @jsxImportSource @emotion/react */

import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, message } from "antd";
import { AnnouncementApi } from "api/announcement";
import { MemberMessageResponseType } from "api/announcement/type";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { READ_VALUE } from "types/promotions";

import { PersonalMessageHeaderStyle } from "./index.style";

const PersonalMessageHeader = () => {
  const { t } = useTranslation(["message"]);
  const { data: username } = useQuery<string>([QueryKeys.username]);
  const { data: response, refetch } = useQuery<MemberMessageResponseType>(
    [QueryKeys.MemberMessage],
    {
      queryFn: async () => {
        return await AnnouncementApi.getMemberMessage({
          username: username as string,
        });
      },
      staleTime: Infinity,
      enabled: !!username,
    },
  );
  const { mutate, isLoading: isLoadingAll } = useMutation({
    mutationKey: ["ReadAllPlayerMessage"],
    mutationFn: AnnouncementApi.readAllMessagePlayer,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      message.error(error as string);
    },
  });
  const data = response?.data || [];
  const showButton = data.some((i) => i.is_read === READ_VALUE.noRead);
  const handleReadAll = () => {
    //
    mutate({
      username: username as string,
    });
  };
  return (
    <div css={PersonalMessageHeaderStyle.wrapper}>
      <header css={PersonalMessageHeaderStyle.self}>
        <div css={PersonalMessageHeaderStyle.left}>
          <h1 css={PersonalMessageHeaderStyle.title}>
            {t("informationCenter")}
          </h1>
          <ul css={PersonalMessageHeaderStyle.tabs}>
            <li css={PersonalMessageHeaderStyle.tabItem}>
              <NavLink to={PATH.PersonalMessage}>
                {t("personalMessage")}
              </NavLink>
              <span></span>
            </li>
            <li css={PersonalMessageHeaderStyle.line}></li>
            <li css={PersonalMessageHeaderStyle.tabItem}>
              <NavLink to={PATH.Annoucement}>{t("notification")}</NavLink>
              <span></span>
            </li>
          </ul>
        </div>
        {showButton ? (
          <Button
            onClick={handleReadAll}
            loading={isLoadingAll}
            type="text"
            css={PersonalMessageHeaderStyle.btnMessage}
          >
            Đọc tất cả tin nhắn
          </Button>
        ) : null}
      </header>
    </div>
  );
};

export default PersonalMessageHeader;
