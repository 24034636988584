/** @jsxImportSource @emotion/react */

import { PATH } from "constants/path";
import i18n from "i18n";
import React from "react";
import { useNavigate } from "react-router-dom";

import { HeaderPageStyle } from "./index.style";
type Props = {
  title: string;
  rightComponent?: React.ReactNode;
  isBackToHome?: boolean;
  onBack?: () => void;
};

function HeaderPage({ title, rightComponent, isBackToHome, onBack }: Props) {
  const navigate = useNavigate();

  const handleBack = () => {
    if (isBackToHome) {
      return navigate(PATH.Home);
    }
    if (onBack) {
      return onBack();
    }
    navigate(-1);
  };

  return (
    <div css={HeaderPageStyle.index(i18n.language)}>
      <div onClick={() => handleBack()} className="back" />
      <h1 className="title">{title}</h1>
      {rightComponent && (
        <div css={HeaderPageStyle.rightContent}>{rightComponent}</div>
      )}
    </div>
  );
}

export default HeaderPage;
