import { css } from "@emotion/react";

export const HotGameStyle = {
  index: (isActive: boolean, language: string) => css`
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    z-index: 8;
    width: 100%;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.95);
    opacity: ${isActive ? 1 : 0};

    .bg_menuShadow {
      width: 100%;
      position: absolute;
      top: 0px;
      background-image: url(${`common/desktop/menu/bg_menuShadow.png`});
      height: 4px;
      z-index: 8;
    }

    .subList {
      --height-menu: 170px;
      margin: 0 auto;
      position: relative;
      width: 1000px;
      height: 100%;
      height: ${isActive ? "var(--height-menu)" : 0};
      transition: all 0.2s;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      &.twoheight {
        --height-menu: 370px;
      }
    }
  `,
  item: (language: string) => css`
    height: 50%;
    width: calc((1000px - 32px) / 5) !important;
    gap: 4px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .image-hot {
      background-image: url(${`common/desktop/menu/img_hot.png`});
      background-size: 100% auto;
      position: absolute;
      top: 16px;
      left: 24px;
      z-index: 101;
      background-repeat: no-repeat;
      background-position: center;
      width: 45px;
      height: 29px;
    }

    &:hover {
      background: linear-gradient(
        to bottom,
        rgba(219, 232, 251, 1) 0%,
        rgba(219, 232, 251, 0.2) 100%
      );

      .name {
        font-weight: 600;
      }
    }

    .image {
      width: 120px;
      img {
        width: 100%;
        object-fit: contain;
        border-radius: 10px;
      }
    }

    .name {
    }
  `,
};
