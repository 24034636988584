/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import { ReactComponent as IconArrow } from "assets/desktop/icons/deposit/icon_arrowW.svg";
import { DepositExample } from "constants/deposit/payment-options";
import React, { useState } from "react";

import { ExampleStepsStyle } from "./index.style";

interface Props {
  isStepMode: boolean;
  data: DepositExample[];
  title?: React.ReactNode;
}

const ExampleSteps = ({ data, isStepMode, title = "" }: Props) => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    if (current === data.length - 1) {
      setCurrent(0);
      return;
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    if (current === 0) {
      setCurrent(data.length - 1);
      return;
    }
    setCurrent(current - 1);
  };

  return (
    <div css={ExampleStepsStyle.self}>
      {isStepMode ? (
        <>
          {data.length ? (
            <>
              <div css={ExampleStepsStyle.stepName}>Bước {current + 1}</div>

              <div
                css={ExampleStepsStyle.stepTexts(data[current].texts.length)}
              >
                {data[current].texts.map((item, index) => (
                  <span key={index} className="step-text">
                    <span className="step-num">
                      {data[current].texts.length > 1 && index + 1}
                    </span>
                    {item}
                  </span>
                ))}
              </div>

              <div css={ExampleStepsStyle.content}>
                {data[current].content}

                <div css={ExampleStepsStyle.btnPrev}>
                  <Button
                    onClick={() => prev()}
                    css={ExampleStepsStyle.btnStep}
                  >
                    <IconArrow />
                  </Button>
                </div>

                <div css={ExampleStepsStyle.btnNext}>
                  <Button
                    type="primary"
                    onClick={() => next()}
                    css={ExampleStepsStyle.btnStep}
                  >
                    <IconArrow />
                  </Button>
                </div>
              </div>

              <div css={ExampleStepsStyle.pageNum}>
                {current + 1} / {data.length}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div css={ExampleStepsStyle.stepPageContent}>
          <div className="title">{title}</div>
          <div className="step-list">
            {data.map((item, index) => (
              <React.Fragment key={index}>{item.content}</React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExampleSteps;
