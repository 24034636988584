import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  WithDrawalFormSettingTypeResponse,
  WithdrawalSubMissionForm,
} from "./type";

export const withdrawApi = {
  async getWithdrawalForm(username: string) {
    const { data } = await axiosRequest.post<WithDrawalFormSettingTypeResponse>(
      "",
      payloadHelper("WithdrawForm", username, { username }),
    );
    if (data.status !== ResponseCode.SUCCESS) {
      throw new Error(data.msg);
    }
    return data.data;
  },

  async withdrawalSubmission(
    username: string,
    payload: WithdrawalSubMissionForm,
  ) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("Withdrawal", username, payload),
    );
    if (data.status !== ResponseCode.SUCCESS) {
      throw new Error(data.msg);
    }
    return data;
  },
};
