import { SubGameType } from "types/game";

import { CATEGORY_CODE } from "./type";

export const listLiveCasino: SubGameType[] = [
  {
    game_code: "",
    name: "Sexy",
    provider_code: "SX",
    provider_name: "Sexy",
    type: CATEGORY_CODE.LIVE_CASINO,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/live-casino/bg_topLive.png") no-repeat;
    background-position: left top !important;
    `,
    icon: "/desktop/live-casino/logo_AES.png",
  },
  {
    game_code: "",
    name: "WM",
    provider_code: "WM",
    provider_name: "WM",
    type: CATEGORY_CODE.LIVE_CASINO,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/live-casino/bg_topLive.png") no-repeat;
    background-position: -400px top !important;
    `,
    icon: "/desktop/live-casino/logo_WM.png",
  },
  {
    game_code: "",
    name: "SA",
    provider_code: "SA",
    provider_name: "SA",
    type: CATEGORY_CODE.LIVE_CASINO,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/live-casino/bg_topLive.png") no-repeat;
    background-position: -600px top !important;
    `,
    icon: "/desktop/live-casino/logo_SA.png",
  },
  {
    game_code: "",
    name: "DG",
    provider_code: "DG",
    provider_name: "DG",
    type: CATEGORY_CODE.LIVE_CASINO,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/live-casino/bg_topLive.png") no-repeat;
    background-position: -200px top !important;
    `,
    icon: "/desktop/live-casino/logo_DG.png",
  },
  {
    game_code: "",
    name: "Evo",
    provider_code: "EVO",
    provider_name: "Evo",
    type: CATEGORY_CODE.LIVE_CASINO,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/live-casino/bg_topLive.png") no-repeat;
    background-position: -400px top !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/live-casino/logo_EVO.png",
  },
  {
    game_code: "",
    name: "PP",
    provider_code: "PP",
    provider_name: "PP",
    type: CATEGORY_CODE.LIVE_CASINO,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/live-casino/bg_topLive.png") no-repeat;
    background-position: right top !important;
    `,
    icon: "/desktop/live-casino/logo_pp.png",
  },
  {
    game_code: "",
    name: "BBIN",
    provider_code: "BBIN",
    provider_name: "BBIN",
    type: CATEGORY_CODE.LIVE_CASINO,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/live-casino/bg_topLive.png") no-repeat;
    background-position: -600px top !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/live-casino/logo_BBIN.png",
  },
];
