/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { useGetProfile } from "api/user/query";
import ChangeLanguage from "components/common/ChangeLanguage";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { useAppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleLogout } from "helpers/handleLogout";
import { handleOpenModal } from "helpers/handleModal";
import { handleNewWindow } from "helpers/handleNewWindow";
import { useTranslation } from "react-i18next";

import GameMenu from "./GameMenu";
import { HeaderStyle } from "./index.style";
import TopTime from "./TopTime";

export const Header = () => {
  const { t, i18n } = useTranslation([
    "home",
    "auth",
    "payment",
    "member",
    "common",
  ]);
  // const isAuthenticated = useQuery<boolean>([QueryKeys.isAuthenticated]);
  const { data: username } = useQuery<string>([QueryKeys.username]);
  const { data: info } = useGetProfile(username as string, !!username);
  const { state, dispatch } = useAppContext();

  return (
    <div css={HeaderStyle.index(i18n.language)}>
      <div css={HeaderStyle.content}>
        <div css={HeaderStyle.topTime}>
          <TopTime />
        </div>
        {!username ? (
          <div css={HeaderStyle.loginArea(i18n.language)}>
            <div
              className="btn_login"
              onClick={() =>
                handleOpenModal(state, dispatch, ModalTypeValue.LOGIN)
              }
            >
              {t("auth:login.self")}
            </div>
            <div
              className="btn_addMember"
              onClick={() =>
                handleOpenModal(state, dispatch, ModalTypeValue.REGISTER)
              }
            >
              {t("auth:register.self")}
            </div>
            <div className="changeLanguage">
              <ChangeLanguage />
            </div>
          </div>
        ) : (
          <div css={HeaderStyle.memberArea(i18n.language)}>
            <ul>
              <li className="memberAC">
                <span className="ng-binding">{info?.username}</span>
                <span className="memberNick">
                  <span className="ng-binding">{info?.full_name}</span>
                </span>
              </li>
              <GameMenu username={info?.username} />
              <li>
                <span className="memberFlow ng-scope">
                  <span
                    className="btn_RoundTextY"
                    onClick={() =>
                      handleNewWindow(PATH.User.Deposit, 1200, 700)
                    }
                  >
                    {t("payment:deposit.self")}
                  </span>
                </span>
              </li>
              <li>
                <span className="memberFlow ng-scope">
                  <span
                    className="btn_RoundTextZ"
                    onClick={() =>
                      handleNewWindow(PATH.User.Withdraw, 1200, 700)
                    }
                  >
                    {t("payment:withdrawal.self")}
                  </span>
                </span>
              </li>

              <li>
                <span
                  onClick={() => {
                    handleOpenModal(state, dispatch, ModalTypeValue.CHECK_IN);
                  }}
                  className="memberFlow ng-scope"
                >
                  <span className="btn_RoundTextX">{t("common:rollCall")}</span>
                </span>
              </li>

              <li>
                <span className="memberCtrl">
                  <span
                    className="btn_member"
                    onClick={() =>
                      handleNewWindow(PATH.User.Profile, 1200, 700)
                    }
                  ></span>
                  <div className="RoundText_prompt">
                    <div className="RT_promptText">{t("member:self")}</div>
                  </div>
                </span>
              </li>
              <li>
                <span className="memberCtrl">
                  <span
                    className="btn_personalMsg"
                    onClick={() =>
                      handleNewWindow(PATH.PersonalMessage, 1200, 700)
                    }
                  ></span>
                  <div className="RoundText_prompt">
                    <div className="RT_promptText">
                      {t("member:personalMessage")}
                    </div>
                  </div>
                </span>
              </li>
              <li onClick={handleLogout}>
                <span className="memberCtrl">
                  <span className="btn_signout"></span>
                  <div className="RoundText_prompt">
                    <div className="RT_promptText">{t("auth:logout.self")}</div>
                  </div>
                </span>
              </li>
            </ul>
            <div className="changeLanguage">
              <ChangeLanguage />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
