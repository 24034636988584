import { css } from "@emotion/react";

export const FooterStyle = {
  index: (language: string) => css`
    width: 100%;
    background-color: #242424;
    padding: 22px 0px 25px 0;
    min-width: 1000px;

    .brandImg {
      background: url(${`common/desktop/menu/provider.png`}) no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0 auto;
      height: 45px;
    }
  `,
};
