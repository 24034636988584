import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";
import { PromotionsListResponseType } from "types/promotions";

import {
  ExperiencePromotionForm,
  ExperiencePromotionResponse,
  GetNbdPromotionResponse,
  MemberPromotionPayload,
  MemberPromotionResponse,
  StoreNbdPromotionProps,
  VerifyBankAccountForm,
  VerifyBankAccountFormProps,
} from "./type";

export const promotionsApi = {
  async promotionsList() {
    const response = await axiosRequest.post<PromotionsListResponseType>(
      "",
      payloadHelper("PromotionList", null, null),
    );
    if (response.data.status === ResponseCode.SUCCESS) {
      return response.data.data;
    }
    throw new Error(response.data.msg);
  },

  async memberPromotion(payload: MemberPromotionPayload) {
    const { data } = await axiosRequest.post<MemberPromotionResponse>(
      "",
      payloadHelper("MemberPromotion", payload.username, payload),
    );
    if (data.status === ResponseCode.SUCCESS) {
      return data;
    }
    throw new Error(data.msg);
  },

  async getNbdPromotion(username: string) {
    const { data } = await axiosRequest.post<GetNbdPromotionResponse>(
      "",
      payloadHelper("GetNbdPromotion", username, { username }),
    );
    if (data.status === ResponseCode.SUCCESS) {
      return data;
    }
    throw new Error(data.msg);
  },
  async storeNbdPromotion(payload: StoreNbdPromotionProps) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("StoreNbdPromotion", payload.username, payload),
    );
    if (data.status === ResponseCode.SUCCESS) {
      return data;
    }
    throw new Error(data.msg);
  },
  async verifyBankAccountForm(username: string) {
    const { data } = await axiosRequest.post<VerifyBankAccountFormProps>(
      "",
      payloadHelper("VerifyBankAccountForm", username, { username }),
    );
    if (data.status === ResponseCode.SUCCESS) {
      return data.data.player_bank;
    }
    throw new Error(data.msg);
  },
  async experiencePromotion(payload: ExperiencePromotionForm) {
    try {
      const { data } = await axiosRequest.post<ExperiencePromotionResponse>(
        "",
        payloadHelper("ExperiencePromotion", payload.username, payload),
      );
      return data;
    } catch (error) {
      throw new Error(error + "");
    }
  },

  async verifyBankAccount(payload: VerifyBankAccountForm) {
    try {
      const { data } = await axiosRequest.post<ApiResponse>(
        "",
        payloadHelper("VerifyBankAccount", payload.username, payload),
      );
      return data;
    } catch (error) {
      throw new Error(error + "");
    }
  },
};
