/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { UserApi } from "api/user";
import { QueryKeys } from "constants/queryKeys";
import { formatCurrency } from "helpers/formatCurrency";
import i18n from "i18n";
import { Fragment } from "react";
import { BalanceType } from "types/user";

import { BalanceStyle } from "./index.style";

interface BalanceProps {
  color?: string;
}

function Balance({ color = "#feea11" }: BalanceProps) {
  const { data: username } = useQuery<string>([QueryKeys.username]);
  const { data: balance, isLoading } = useQuery<BalanceType>(
    [QueryKeys.GetBalanceDV],
    {
      queryFn: async () => {
        return await UserApi.getMemberBalance({
          username: username as string,
        });
      },
      enabled: username ? true : false,
      refetchOnWindowFocus: false,
    },
  );
  // const [open, setOpen] = useState<boolean>(false);
  if (isLoading) return null;
  return (
    <Fragment>
      <div css={BalanceStyle.index(false, color, i18n.language)}>
        <div
          // onClick={() => {
          //   setOpen(true);
          // }}
          className="icon_inforMoney"
        >
          <span className="ng-binding ng-scope">
            {formatCurrency(Math.trunc(Number(balance?.total)) || 0)}
          </span>
          {/* <span className="btn_inforOut"></span> */}
        </div>
      </div>
      {/* <GameMenu open={open} onClose={() => setOpen(false)} /> */}
    </Fragment>
  );
}

export default Balance;
