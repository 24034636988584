import React, { createContext, Dispatch, useContext } from "react";
import { ContextAction } from "types";
import { DepositFormSettingType } from "types/deposit";

import { depositReducer } from "./deposit.reducer";

export type DepositContextAction = "FORM_SETTING";

export interface DepositState {
  formSetting: DepositFormSettingType | null;
}

interface IDepositContext {
  state: DepositState;
  dispatch: Dispatch<ContextAction<DepositContextAction, DepositState>> | null;
}

export const initialState: DepositState = {
  formSetting: null,
};

export const DepositContext = createContext<IDepositContext>({
  state: initialState,
  dispatch: null,
});

export const DepositContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = React.useReducer(depositReducer, initialState);
  return (
    <DepositContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DepositContext.Provider>
  );
};

export const useDepositContext = () =>
  useContext<IDepositContext>(DepositContext);
