import { css } from "@emotion/react";

export const HeaderPageStyle = {
  index: (language: string) => css`
    background: #0f548b;
    color: #fff;
    height: 40px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .back {
      background: url(${`/common/mobile/header/icon_arrowW.svg`}) no-repeat
        center;
      background-size: auto 40%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 40px;
      cursor: pointer;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
    .title {
      font-size: 1em;
      font-weight: normal;
      margin: 0 40px;
      text-align: center;
      white-space: nowrap;
      line-height: 40px;
      flex: 1;
      text-transform: uppercase;
    }
  `,
  rightContent: css`
    position: absolute;
    top: 0;
    right: 10px;
    height: 100%;
    width: auto;
    z-index: 2;
    display: flex;
    align-items: center;
  `,
};
