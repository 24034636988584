export enum Language {
  ENGLISH = "en",
  VIETNAM = "vi",
}

export enum Syslang {
  ENGLISH = 1,
  CHINESE_SIMPLIFIED = 2,
  CHINESE_TRADITIONAL = 3,
  THAILAND = 6,
  VIETNAM = 7,
}
