import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";
import {
  CheckInType,
  GetListsCheckInType,
  MemberBalancePayload,
  MemberBalanceResType,
} from "types/user";

import {
  ChangePasswordPayload,
  CheckFullNameForm,
  CheckFullNameResponse,
  GetProfilePayload,
  GetProfileResponse,
  JackpostResponseType,
  UpdateProfilePayload,
  UpdateProfileResponse,
} from "./type";

export const UserApi = {
  updateProfile: async (typename: string, payload: UpdateProfilePayload) => {
    const { data } = await axiosRequest.post<UpdateProfileResponse>(
      "/",
      payloadHelper("UpdateMemberDetail", typename, payload),
    );
    if (data.status === ResponseCode.SUCCESS) {
      return data;
    }
    throw new Error(data.msg);
  },

  changePassword: async (typename: string, payload: ChangePasswordPayload) => {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ChangePassword", typename, payload),
    );
    if (data.status === ResponseCode.SUCCESS) {
      return data;
    }
    throw new Error(data.msg);
  },

  async getProfile(payload: GetProfilePayload) {
    const response = await axiosRequest.post<GetProfileResponse>(
      "/",
      payloadHelper("MemberDetail", payload.username, payload),
    );
    if (response.data.status === ResponseCode.SUCCESS) {
      return response.data;
    }
    throw new Error(response.data.msg);
  },

  getMemberBalance: async (payload: MemberBalancePayload) => {
    const response = await axiosRequest.post<MemberBalanceResType>(
      "",
      payloadHelper("GetBalanceDV", payload.username, payload),
    );
    if (response.data.status === ResponseCode.SUCCESS) {
      return response.data.data;
    }
    throw new Error(response.data.msg);
  },
  getListCheckIn: async () => {
    try {
      const username = localStorage.getItem("username") + "";
      const response = await axiosRequest.post<GetListsCheckInType>(
        "",
        payloadHelper("GetListsCheckIn", username, { username }),
      );
      if (response.data.status === ResponseCode.SUCCESS) {
        return response.data.data;
      }
      throw new Error(response.data.msg);
    } catch (error) {
      throw new Error(error + "");
    }
  },

  CheckIn: async () => {
    try {
      const username = localStorage.getItem("username") + "";
      const response = await axiosRequest.post<CheckInType>(
        "",
        payloadHelper("CheckIn", username, { username }),
      );
      if (response.data.status === ResponseCode.SUCCESS) {
        return response.data.data;
      }
      throw new Error(response.data.msg);
    } catch (error) {
      throw new Error(error + "");
    }
  },
  ClaimCheckIn: async () => {
    try {
      const username = localStorage.getItem("username") + "";
      const response = await axiosRequest.post<ApiResponse>(
        "",
        payloadHelper("ClaimCheckIn", username, { username }),
      );
      if (response.data.status === ResponseCode.SUCCESS) {
        return response.data;
      }
      throw new Error(response.data.msg);
    } catch (error) {
      throw new Error(error + "");
    }
  },
  getJackpot: async () => {
    const res = await axiosRequest.post<JackpostResponseType>(
      "/",
      payloadHelper("GetJackpot", null, null),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data.data;
    }
    throw new Error(res.data.msg);
  },
  checkPlayerFullname: async (payload: CheckFullNameForm) => {
    try {
      const response = await axiosRequest.post<CheckFullNameResponse>(
        "",
        payloadHelper("CheckPlayerFullname", payload.username, payload),
      );
      if (response.data.status === ResponseCode.SUCCESS) {
        return response.data;
      }
      throw new Error(response.data.msg);
    } catch (error) {
      throw new Error(error + "");
    }
  },
};
