import { css } from "@emotion/react";

export const ModalMessageStyle = {
  index: css`
    width: 100%;
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: transparent linear-gradient(180deg, #8effe6 0%, #26a3c6 100%) 0%
      0% no-repeat;
    padding: 0.625rem 0.875rem;

    i {
      position: absolute;
      right: 10px;
      top: 0;
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #fff;
    }

    h3 {
      color: #fff;
      font-size: 1.125rem;
      font-weight: 400;
    }
  `,
  content: css`
    .image {
      width: 100%;
      img {
        width: 100%;
      }
    }
  `,
  button: css`
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      object-fit: cover;
    }
  `,
};
