/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { gameProviderJson } from "constants/desktop-game";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import handleLoginGame from "helpers/handleLoginGame";
import i18next from "i18next";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import DropdownMenu from "./DropdownMenu";
import HotGame from "./HotGame";
import { MainMenuStyle } from "./index.style";
import { ItemMenu, listMenu } from "./list-menu";

function MainMenu() {
  const { t, i18n } = useTranslation(["home"]);
  const { data: username } = useQuery<string>([QueryKeys.username]);

  const [activeCode, setActiveCode] = useState<string | undefined>();

  const onClickItem = (item: ItemMenu) => {
    if (item.isPopup) {
      window.open(
        PATH.promotion,
        "modal",
        `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=1100,height=600,left=-500,top=100`,
      );
      return;
    }
    if (item.code && item.code.includes("#")) return;

    handleClickMenu(item);
  };
  const handleClickMenu = (item: ItemMenu) => {
    const games = gameProviderJson[item.code + ""];
    if (item.isSubGame) {
      return window.open(`/${item.code}/${games[0].provider_code}`);
    }
    if (!games?.length) {
      message.error({
        key: "Game",
        content: i18next.t("message:game_maintenance"),
      });
      return;
    }
    handleLoginGame(games[0], username as string);
  };
  return (
    <div css={MainMenuStyle.index(i18n.language)}>
      <div className="menuArea ng-scope">
        <div className="mainMenu">
          <Link to={PATH.Home} className="logo">
            <img loading="lazy" src={"common/desktop/logo.png"} alt="" />
          </Link>
          <div className="menu">
            <ul>
              {listMenu.map((item, index) => {
                const isActive = item.code === activeCode;
                return (
                  <Fragment key={index}>
                    <li
                      onMouseLeave={() => setActiveCode(undefined)}
                      onMouseEnter={() => setActiveCode(item.code)}
                      className={`${activeCode === item.code ? "hover" : ""}`}
                    >
                      <div
                        className={
                          item.path === PATH.promotion
                            ? "menuA_colorO"
                            : `menuA `
                        }
                        onClick={() => {
                          if (item.path.includes("http")) {
                            return window.open(item.path);
                          }
                          onClickItem(item);
                        }}
                      >
                        {item.path === PATH.HOT && (
                          <img
                            loading="lazy"
                            className="iconActivity"
                            src={i18n.language + "/desktop/menu/icon_fire.gif"}
                            alt=""
                          />
                        )}
                        {item.path === PATH.PhoneApp && (
                          <div className="icon_phone" />
                        )}
                        {t(`desktop.menu.${item.code}`)}
                      </div>
                      {item.code && item.code === "HOT" ? (
                        <HotGame isActive={isActive} menuItem={item} />
                      ) : item.code && !item.code.includes("#") ? (
                        <DropdownMenu isActive={isActive} menuItem={item} />
                      ) : null}
                    </li>
                    {index < listMenu.length - 1 && (
                      <li className="line">
                        <span>|</span>
                      </li>
                    )}
                  </Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainMenu;
