/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Modal } from "antd";
import { promotionsApi } from "api/promotions";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import { useAppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleCloseModal } from "helpers/handleModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ModalMessageStyle } from "./style";

function ModalMessage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const { state, dispatch } = useAppContext();
  const { data: username } = useQuery([QueryKeys.username]);
  const { data } = useQuery({
    queryKey: ["ExperiencePromotion"],
    queryFn: async () => {
      return await promotionsApi.experiencePromotion({
        username: username as string,
      });
    },
    enabled: !!username,
  });

  const { mutateAsync } = useMutation({
    mutationKey: ["ExperiencePromotion"],
    mutationFn: promotionsApi.experiencePromotion,
  });

  return (
    <Modal
      width={430}
      open={state.openModal === ModalTypeValue.MESSAGE}
      onCancel={() => handleCloseModal(state, dispatch)}
      centered
      bodyStyle={{ padding: "0" }}
      className="register-modal"
      closable={false}
      footer={null}
      css={css`
        .ant-modal-content {
          padding: 0 !important;
          border-radius: 15px;
          overflow: hidden;
        }
      `}
    >
      <div css={ModalMessageStyle.index}>
        <div css={ModalMessageStyle.header}>
          <h3>{t("messageModal.title")}</h3>
          <i
            onClick={() => handleCloseModal(state, dispatch)}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div css={ModalMessageStyle.content}>
          <div className="image">
            <img
              src={`/${i18n.language}/popup-message.png`}
              alt=""
              onError={(e) => {
                e.currentTarget.src = "/vi/popup-message.png";
              }}
            />
          </div>
          <div
            onClick={async () => {
              if (data?.status === 6 && data.join === "B") {
                await mutateAsync({
                  username: username as string,
                  clickB: "1",
                });
              }
              handleCloseModal(state, dispatch);
              navigate(PATH.User.Profile + "?type=promotion");
            }}
            css={ModalMessageStyle.button}
          >
            <img src={`/${i18n.language}/gold-button.png`} alt="" />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalMessage;
