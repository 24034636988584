/** @jsxImportSource @emotion/react */

import { MENU_USER_LAYOUT } from "constants/menu-user-layout";
import _map from "lodash/map";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { HeaderUserLayoutStyle } from "./index.style";

const HeaderUserLayout = () => {
  const { t } = useTranslation(["member", "common", "payment", "transaction"]);

  return (
    <div css={HeaderUserLayoutStyle.wrapper}>
      <header css={HeaderUserLayoutStyle.self}>
        <h1 css={HeaderUserLayoutStyle.title}>{t("self")}</h1>
        <ul css={HeaderUserLayoutStyle.tabs}>
          {_map(MENU_USER_LAYOUT, (item, index) => (
            <React.Fragment key={index}>
              <li css={HeaderUserLayoutStyle.tabItem}>
                <NavLink to={item.path}>{t(item.name)}</NavLink>
                <span></span>
              </li>

              {index < MENU_USER_LAYOUT.length - 1 && (
                <li css={HeaderUserLayoutStyle.line}></li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </header>
    </div>
  );
};

export default HeaderUserLayout;
