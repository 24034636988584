/** @jsxImportSource @emotion/react */

import { DEPOSIT_PAYMENT_OPTIONS } from "constants/deposit/payment-options";
import _find from "lodash/find";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import ExampleSteps from "../DepositPage/ExampleSteps";
import { DepositExampleStyle } from "./index.style";

const DepositExample = () => {
  const { id } = useParams();

  const data = useMemo(() => {
    if (!id) {
      return undefined;
    }
    return _find(DEPOSIT_PAYMENT_OPTIONS, (item) => item.id === +id);
  }, [id]);

  return (
    <div css={DepositExampleStyle.self}>
      {data?.isStepMode && (
        <div css={DepositExampleStyle.title}>{data?.title}</div>
      )}
      <div css={DepositExampleStyle.content}>
        <ExampleSteps
          data={data?.examples || []}
          isStepMode={!!data?.isStepMode}
          title={data?.title}
        />
      </div>
    </div>
  );
};

export default DepositExample;
