import { contactUs } from "constants/contact-us";

export const listServices = [
  // {
  //   name: "Hỗ trợ",
  //   path: contactUs.hotline,
  //   position: "-2px -744px",
  // },
  {
    name: "Telegram",
    path: contactUs.telegram,
    value: "ID: Mbet888888",
    position: "-251px -746px",
    isQRCode: true,
  },
  {
    name: "Viber",
    path: contactUs.viber,
    value: "ID: +" + contactUs.viber.split("=")[1],
    position: "-201px -746px",
    isQRCode: true,
    disable: true,
  },
  {
    name: "Zalo",
    path: contactUs.zalo,
    position: "-51px -745px",
  },
  // {
  //   name: "Hỗ trợ xa",
  //   path: contactUs.computer,
  //   position: "-101px -746px",
  // },
  // {
  //   name: "Khiếu nại",
  //   path: contactUs.complaint,
  //   position: "-151px -746px",
  // },
];
