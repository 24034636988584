export const formatCurrency = (number: number) => {
  const parts = number.toString().split(",");
  let integerPart = parts[0];
  const decimalPart = parts.length > 1 ? "," + parts[1] : "";
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return integerPart + decimalPart;
};

export const formatCurrencyRate = (number: number) => {
  // Chuyển đổi số thành chuỗi và tách phần nguyên và phần thập phân
  const parts = number.toFixed(2).split(".");

  // Lấy phần nguyên
  let integerPart = parts[0];

  // Loại bỏ dấu phẩy ở phần nguyên
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Kết hợp phần nguyên và phần thập phân
  return integerPart;
};
