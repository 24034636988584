import { Spin } from "antd";
import { PATH } from "constants/path";
import { useAppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { handleOpenModal } from "helpers/handleModal";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "router";

function ReferCode() {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();
  const { code } = useParams();
  useEffect(() => {
    if (!code) {
      navigate(PATH.Home, { replace: true });
      return;
    }
    if (isMobile) {
      navigate(PATH.Home, { replace: true });
      navigate(PATH.Register + `?code=${code}`);
      return;
    }
    navigate(`${PATH.Home}?code=${code}`, { replace: true });
    handleOpenModal(state, dispatch, ModalTypeValue.REGISTER, code);
  }, [code]);
  return (
    <div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          flexDirection: "column",
          position: "fixed",
          zIndex: 1020,
          background: "#fff",
          alignItems: "center",
          justifyContent: "center",
          display: "none",
        }}
        id="loading-app"
        className="center-box"
      >
        <img loading="lazy" style={{ width: 120 }} src="/logo.png" alt="" />
        <Spin />
      </div>
    </div>
  );
}

export default ReferCode;
