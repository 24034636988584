import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";
import {
  CategoryDetailsResponseType,
  LoginGameResponseType,
  LoginGameType,
  SubGamePayLoad,
  SubGameResponse,
} from "types/game";

export const gameApi = {
  loginGame: (username: string, payload: LoginGameType) =>
    axiosRequest.post<LoginGameResponseType>(
      "",
      payloadHelper("LoginGame", username, payload),
      {
        cancelToken: payload.source?.token,
      },
    ),

  getSubGameList: async (payload: SubGamePayLoad) => {
    const res = await axiosRequest.post<SubGameResponse>(
      "",
      payloadHelper("GetSubGameList", "", payload),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },

  getGameList: (game_type: string) =>
    axiosRequest.post<CategoryDetailsResponseType>(
      "",
      payloadHelper("GetGameList", game_type, { game_type }),
    ),
};
