import { css } from "@emotion/react";

export const EmptyComponentStyle = {
  self: css`
    /*  */
  `,

  emptyIcon: css`
    width: 137px;
  `,

  emptyMsg: css`
    text-align: center;
    color: #72a5dc;
    font-weight: bold;
    font-size: 17.6px;
    margin-top: 5px;
  `,
};
