/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { PATH } from "constants/path";
import { QueryKeys } from "constants/queryKeys";
import i18n from "i18n";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import FooterOpen from "./FooterOpen";
import { BottomTabsStyle } from "./index.style";
import { listBottomTabs } from "./list-bottom";

function BottomTabs() {
  const { t } = useTranslation("home");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const { data: isAuthenticated } = useQuery<boolean>([
    QueryKeys.isAuthenticated,
  ]);
  return (
    <Fragment>
      <div css={BottomTabsStyle.index}>
        <div className="footer_mask_all" />
        <div css={BottomTabsStyle.content}>
          {listBottomTabs.map((item, index) => {
            const isActive = item.center
              ? false
              : item.isIncluded && pathname !== PATH.User.Transaction
              ? pathname.includes(item.path)
              : item.path === pathname;
            const isHome = pathname === PATH.Home;
            if (index === 0 && !isHome) return null;
            if (index === 1 && isHome) return null;
            return (
              <div
                css={BottomTabsStyle.item(item, i18n.language)}
                className={`${item.className} ${item.center ? "center" : ""} ${
                  isActive ? "active" : ""
                }`}
                key={index}
                onClick={() => {
                  if (item.path === pathname) return;
                  if (item.isLogin && !isAuthenticated) {
                    // login action
                    message.destroy();
                    message.warning(t("mobile.requestLogin"));
                    return;
                  }
                  if (item.center) {
                    return setOpen((prev) => !prev);
                  }
                  setOpen(false);
                  if (!item.path) return;
                  return navigate(item.path);
                }}
              >
                <h5>{t(`mobile.bottom-tab.${item.name}`)}</h5>
              </div>
            );
          })}
        </div>
      </div>
      <FooterOpen open={open} onClose={() => setOpen(false)} />
    </Fragment>
  );
}

export default BottomTabs;
