import { CATEGORY_CODE } from "constants/desktop-game/type";
import { DownloadApp, PATH } from "constants/path";

export interface ItemMenu {
  name: string;
  image?: string;
  path: string;
  code?: string;
  isPopup?: boolean;
  isSubGame?: boolean;
}

export const listMenu: ItemMenu[] = [
  // {
  //   name: "HOT GAME",
  //   path: PATH.HOT,
  //   code: CATEGORY_CODE.HOT,
  // },
  {
    name: "THỂ THAO",
    path: `/${CATEGORY_CODE.SPORT}`,
    code: CATEGORY_CODE.SPORT,
  },
  {
    name: "LIVE CASINO",
    path: `/${CATEGORY_CODE.LIVE_CASINO}`,
    code: CATEGORY_CODE.LIVE_CASINO,
  },
  {
    name: "GAMES",
    path: `/${CATEGORY_CODE.GAMES}`,
    code: CATEGORY_CODE.GAMES,
    isSubGame: true,
  },
  {
    name: "XỔ SỐ",
    path: `/${CATEGORY_CODE.LOTTERY}`,
    code: CATEGORY_CODE.LOTTERY,
  },
  {
    name: "E-SPORTS",
    path: `/${CATEGORY_CODE.E_SPORT}`,
    code: CATEGORY_CODE.E_SPORT,
  },
  {
    name: "BẮN CÁ",
    path: `/${CATEGORY_CODE.FISHING}`,
    code: CATEGORY_CODE.FISHING,
    isSubGame: true,
  },
  {
    name: "ĐỐI KHÁNG",
    path: `/${CATEGORY_CODE.CHESS_GAME}`,
    code: CATEGORY_CODE.CHESS_GAME,
    isSubGame: true,
  },
  {
    name: "COCK FIGHTING",
    path: `/${CATEGORY_CODE.COCK_FIGHTING}`,
    code: CATEGORY_CODE.COCK_FIGHTING,
  },
  {
    name: "ƯU ĐÃI",
    path: PATH.promotion,
    isPopup: true,
    code: "#1",
  },
  {
    name: "TẢI APP",
    path: DownloadApp,
    code: "#2",
  },
];
