import "./i18n";

import { ThemeProvider } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";
import { AuthApi } from "api/auth";
import { UserApi } from "api/user";
import { useGetProfile } from "api/user/query";
import { QueryKeys } from "constants/queryKeys";
import { handleLogout } from "helpers/handleLogout";
import { closeLoadingApp, openLoadingApp } from "helpers/loadingApp";
import { useIP } from "helpers/useIP";
import { queryClient } from "index";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "router";
import GlobalStyles from "styles/global";
import { theme } from "styles/theme";
import { BalanceType } from "types/user";

function App() {
  useIP();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const username = localStorage.getItem("username") || "";
  const token = localStorage.getItem("token");
  const prevPath = useRef<string>("");

  !!username && queryClient.setQueryData([QueryKeys.username], username);
  !!token && queryClient.setQueryData([QueryKeys.token], token);

  const { data: tokenCache } = useQuery([QueryKeys.token]);
  const { data: usernameCache } = useQuery([QueryKeys.username]);
  const { data: isAuthenticated } = useQuery([QueryKeys.isAuthenticated]);
  useGetProfile(username, !!username);
  const { data: balance } = useQuery<BalanceType>([QueryKeys.GetBalanceDV], {
    queryFn: () =>
      UserApi.getMemberBalance({
        username: username as string,
      }),
    enabled: !!username,
    onSuccess: (data) => {
      if (data.total) {
        localStorage.setItem("totalBalance", data.total);
      }
    },
    refetchOnWindowFocus: true,
  });

  // const appRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [refresh, setRefresh] = useState(false);
  useQuery({
    queryKey: ["fetchToken", usernameCache, tokenCache],
    queryFn: async () => {
      if (!tokenCache) return "Token invalid";
      if (!usernameCache) return "username invalid";
      try {
        const response = await AuthApi.verifyToken(usernameCache as any, {
          username: usernameCache as any,
          token: tokenCache as any,
        });

        if (response.status !== 0) {
          handleLogout();
          navigate("/");
        } else {
          if (!isAuthenticated) {
            queryClient.setQueryData([QueryKeys.isAuthenticated], true);
          }
        }
        return response;
      } catch (error) {
        return error;
      }
    },
    enabled: tokenCache ? true : false,
    // refetchInterval: isAuthenticated ? 1000 * 15 : false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (
        e.key === "token" ||
        (e.key === "i18nLng" && e.newValue === "vi" && e.oldValue === null)
      ) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // const handleInteraction = () => {
  //   if (!isAuthenticated) return;
  //   if (appRef.current) {
  //     clearTimeout(appRef.current);
  //   }
  //   appRef.current = setTimeout(
  //     () => {
  //       handleLogout();
  //     },
  //     1000 * 60 * 60,
  //   );
  // };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     handleInteraction();
  //   } else {
  //     if (appRef.current) {
  //       clearTimeout(appRef.current);
  //     }
  //   }
  // }, [isAuthenticated]);

  useEffect(() => {
    if (!isMobile) return;
    openLoadingApp();
    setTimeout(() => {
      closeLoadingApp();
    }, 1000);
  }, []);

  useEffect(() => {
    if (pathname !== prevPath.current) {
      sessionStorage.setItem("historyPath", prevPath.current);
      prevPath.current = pathname;
    }
  }, [pathname]);

  useEffect(() => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 500);
  }, [i18n.language]);

  useEffect(() => {
    const totalBalance = localStorage.getItem("totalBalance");
    if (!balance || !totalBalance || balance.total === totalBalance) return;
    localStorage.setItem("totalBalance", balance.total);
  }, [balance]);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      const updatedValue = localStorage.getItem("totalBalance");
      if (updatedValue !== balance?.total) {
        queryClient.setQueryData([QueryKeys.GetBalanceDV], {
          ...balance,
          total: updatedValue,
        });
      }
    });
  }, []);

  return (
    <div
    // onMouseEnter={() => handleInteraction()}
    // onKeyDown={() => handleInteraction()}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <div
          style={{
            height: "100vh",
            width: "100vw",
            flexDirection: "column",
            position: "fixed",
            zIndex: 1020,
            background: "#fff",
            alignItems: "center",
            justifyContent: "center",
            display: "none",
          }}
          id="loading-app"
          className="center-box"
        >
          <img loading="lazy" style={{ width: 120 }} src="/logo.png" alt="" />
          <Spin />
        </div>
        {!refresh && <Outlet />}
      </ThemeProvider>
    </div>
  );
}

export default App;
