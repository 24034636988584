import { SubGameType } from "types/game";

import { CATEGORY_CODE } from "./type";

export const listSlots: SubGameType[] = [
  {
    game_code: "",
    name: "IBEX",
    provider_code: "IBEX",
    provider_name: "IBEX",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position:-800px !important;
    background-position-y: top !important;
    `,
    icon: "/desktop/slots/ibex.png",
  },
  {
    game_code: "",
    name: "ATG",
    provider_code: "ATG",
    provider_name: "ATG",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position:-1004px !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/slots/logo_atg.png",
  },
  {
    game_code: "",
    name: "Jili",
    provider_code: "JILI",
    provider_name: "Jili",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position: -600px top !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/slots/logo-JILI.png",
  },
  {
    game_code: "",
    name: "JDB Gaming",
    provider_code: "JDB",
    provider_name: "JDB Gaming",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position: -400px top !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/slots/logo-JDB.png",
  },
  {
    game_code: "",
    name: "KA",
    provider_code: "KA",
    provider_name: "KA",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position: left top !important;
    `,
    icon: "/desktop/slots/logo_KA.png",
  },
  {
    game_code: "",
    name: "Booongo",
    provider_code: "BNG",
    provider_name: "Booongo",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position: -200px top !important;
    `,
    icon: "/desktop/slots/logo_bng.png",
  },
  {
    game_code: "",
    name: "Pocket Games Soft",
    provider_code: "PGS2",
    provider_name: "Pocket Games Soft",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position: -600px top !important;
    `,
    icon: "/desktop/slots/logo-PGS.png",
  },
  {
    game_code: "",
    name: "Funta Gaming",
    provider_code: "FTG",
    provider_name: "Funta Gaming",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position: -200px top !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/slots/logo-FUNTA.png",
  },
  {
    game_code: "",
    name: "Dragon Gaming",
    provider_code: "DGG",
    provider_name: "Dragon Gaming",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position: -400px top !important;
    `,
    icon: "/desktop/slots/logo_DS.png",
  },
  {
    game_code: "",
    name: "Fachai Gaming",
    provider_code: "FC",
    provider_name: "Fachai Gaming",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/slots/logo-FC.png",
  },
  {
    game_code: "",
    name: "CQ9 Gaming",
    provider_code: "CQ9",
    provider_name: "CQ9 Gaming",
    type: CATEGORY_CODE.GAMES,
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/slots/bg_topGame.png") no-repeat;
    background-position: -796px !important;
    background-position-y: bottom !important;
    `,
    icon: "/desktop/slots/logo_cq9.png",
  },
];
