import { SubGameType } from "types/game";

export const listBoardGame: SubGameType[] = [
  {
    game_code: "",
    name: "Kingmaker",
    provider_code: "KM",
    provider_name: "Kingmaker",
    type: "BG",
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/board-game/bg_topChess.png") no-repeat;
    background-position: 60px top !important;
    background-size: cover;
    `,
    icon: "/desktop/board-game/logo_KM.png",
  },
  {
    game_code: "",
    name: "V8 Poker",
    provider_code: "V8",
    provider_name: "V8 Poker",
    type: "BG",
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/board-game/bg_topChess.png") no-repeat;
    background-position: -290px top !important;
    background-size: cover;
    `,
    icon: "/desktop/board-game/logo_V8.png",
  },
  {
    game_code: "",
    name: "Gordon",
    provider_code: "GFGD",
    provider_name: "Gordon",
    type: "BG",
    featured: "",
    new_arrive: "",
    picture: `
    background:url("/desktop/board-game/bg_topChess.png") no-repeat;
    background-position: -912px top !important;
    background-size: cover;
    `,
    icon: "/desktop/board-game/Gordon_logo.png",
  },
];
