import loadable from "@loadable/component";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys";
import { useAppContext } from "contexts/app.context";
import { ModalTypeValue } from "enums/modalType";
import { isMobile } from "router";

import ModalMessage from "./ModalMessage";

const LoginModalDesktop = loadable(() => import("./LoginModal/Desktop"));
const LoginModalMobile = loadable(() => import("./LoginModal/Mobile"));
const RegisterModal = loadable(() => import("./RegisterModal"));
const ForgotPasswordModal = loadable(() => import("./ForgotPasswordModal"));
const AddBankModal = loadable(() => import("./AddBankModal"));
const CheckIn = loadable(() => import("./CheckIn"));

export const Modal = () => {
  const { state } = useAppContext();
  const { data: isAuth } = useQuery<boolean>({
    queryKey: [QueryKeys.isAuthenticated],
  });

  return (
    <>
      {isMobile ? <LoginModalMobile /> : <LoginModalDesktop />}
      {state.openModal === ModalTypeValue.REGISTER ? <RegisterModal /> : null}
      <ForgotPasswordModal />
      {state.openModal === ModalTypeValue.ADD_BANK ? <AddBankModal /> : null}
      {isAuth ? <CheckIn /> : null}
      {state.openModal === ModalTypeValue.MESSAGE ? <ModalMessage /> : null}
    </>
  );
};
