export const userPath = "/user";

export const ErrorImage = "/image-error.png";
export const DownloadApp = "https://bd.clientaa.top/";
export const downloadAppAndroid = "https://mbet8.net/app/mbet88.apk";

export const PATH = {
  Home: "/",
  // menu
  HOT: "/hot",
  SPORT: "/sport",
  LiveCasino: "/live-casino",
  Games: "/games",
  Lottery: "/lottery",
  ESports: "/e-sports",
  Fishing: "/fishing",
  ChessGames: "/chess-games",
  Event: "/event",
  SubGame: "/:category",
  Annoucement: "/announcement",
  PersonalMessage: "/personal-message",
  PhoneApp: "/PhoneApp",
  Register: "/register",
  depositSuccess: "/deposit-success",
  //
  promotion: "/promotion",
  ServiceCallback: "/service-callback",
  CustomerTeach: "/customer-teach",
  Rules: "/rules",
  ReferCode: "/refer/:code",
  AboutUS: {
    index: "/about-us",
    introduction: "/about-us/introduction",
    spirit: "/about-us/spirit",
    venueInfo: "/about-us/venue-info",
  },
  HelpCenter: {
    index: "/help-center",
    HelpContact: "/help-center/help-contact",
    HelpQA: "/help-center/help-qa",
    TeachHelp: "/help-center/teach-help",
    TeachSystem: "/help-center/teach-system",
    HelpRemote: "/help-center/help-remote",
    ServiceCenter: "/help-center/service-center",
  },
  GameTerms: {
    index: "/game-terms",
    ServiceRule: "/game-terms/service-rule",
    Privacy: "/game-terms/privacy",
    Responsible: "/game-terms/responsible",
    Safety: "/game-terms/safety",
    Statement: "/game-terms/statement",
  },
  User: {
    index: userPath,
    Profile: userPath + "/profile",
    ChangePassword: userPath + "/change-password",
    PlatformTransfer: userPath + "/platform-transfer",
    Deposit: userPath + "/deposit",
    Withdraw: userPath + "/withdraw",
    Transaction: userPath + "/transaction",
    MemberPromotion: userPath + "/member-promotion",
    AddMemberBank: userPath + "/add-bank",
    DepositExample: userPath + "/deposit/example",
    DepositExampleDetail: userPath + "/deposit/example/:id",
  },
};
