import { css } from "@emotion/react";

export const UserLayoutStyle = {
  self: css`
    min-height: 100vh;
    background-color: #f3f3f3;
    overflow: auto;
  `,

  main: css`
    width: 950px;
    margin: 0 auto;
    min-height: calc(100vh - 60px);
  `,
};
