import { PATH } from "constants/path";
import { queryClient } from "index";

export const handleLogout = () => {
  localStorage.clear();
  queryClient.resetQueries();
  queryClient.clear();
  if (window.location.pathname !== PATH.Home) {
    window.location.href = PATH.Home;
  }
  window.location.reload();
};
