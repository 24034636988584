import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  ListBankResponseType,
  ListPlayerBankResponseType,
  PlayerBankFormType,
  RemoveMemberBankPayload,
} from "./type";

export const BankApi = {
  async getListBank(username: string) {
    const { data } = await axiosRequest.post<ListBankResponseType>(
      "",
      payloadHelper("GetBankList", username, { username }),
    );
    if (data.status !== ResponseCode.SUCCESS) {
      throw new Error(data.msg);
    }
    return data;
  },

  async getListPlayerBank(username: string) {
    const { data } = await axiosRequest.post<ListPlayerBankResponseType>(
      "",
      payloadHelper("GetMemberBank", username, { username }),
    );
    if (data.status !== ResponseCode.SUCCESS) {
      throw new Error(data.msg);
    }
    return data.data;
  },

  async addPlayerBank(username: string, payload: PlayerBankFormType) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("AddMemberBank", username, payload),
    );
    if (data.status !== ResponseCode.SUCCESS) {
      throw new Error(data.msg);
    }
    return data;
  },

  async removeMemberBank(payload: RemoveMemberBankPayload) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("RemoveMemberBank", payload.username, payload),
    );
    if (data.status !== ResponseCode.SUCCESS) {
      throw new Error(data.msg);
    }
    return data;
  },
};
