export const contactUs = {
  telegram: "https://t.me/Mbet888888",
  hotline: "+84 355951930",
  viber: "viber://add?number=855967335468",
  phone: "+84 355951930",
  viberMobile: "+855967335468",
  computer: "",
  complaint: "",
  email: "",
  zalo: "",
};
