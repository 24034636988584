import { ContextAction } from "types";

import { WithdrawContextAction, WithdrawState } from "./withdraw.context";

export const withdrawReducer = (
  state: WithdrawState,
  action: ContextAction<WithdrawContextAction, WithdrawState>,
): WithdrawState => {
  switch (action.type) {
    case "PLAYER_BANK_SELECT":
      return {
        ...state,
        playerBankSelected: action.payload.playerBankSelected,
      };
    default:
      return state;
  }
};
