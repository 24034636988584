import { css } from "@emotion/react";

export const KeyboardNumberStyle = {
  index: css`
    width: 100%;

    .grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      height: 52px;

      &:nth-of-type(1) {
        .item {
          &:nth-of-type(1) {
            border-radius: 5px 0 0 0;
          }
          &:nth-of-type(6) {
            border-right: 1px solid #2682d5;
            background-size: auto 50% !important;
            border-radius: 0 5px 0 0;
          }
        }
      }

      &:nth-of-type(2) {
        .item {
          border-bottom: 1px solid #2682d5;
          &:nth-of-type(1) {
            border-radius: 0 0 0 5px;
          }
          &:nth-of-type(5) {
            grid-column: span 2;
            border-right: 1px solid #2682d5;
            border-radius: 0 0 5px 0;
          }
        }
      }
    }
  `,
  item: (icon: string) => css`
    background: url(${icon}) no-repeat center;
    background-size: auto 110%;
    height: 100%;
    border-left: 1px solid #2682d5;
    border-top: 1px solid #2682d5;
    cursor: pointer;
  `,
};
