export enum ModalTypeValue {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  CLOSE = "",
  BANK = "Bank",
  ANNOUNCEMENT = "Announcement",
  ADD_BANK = "ADD_BANK",
  CHECK_IN = "CHECK_IN",
  MESSAGE = "MESSAGE",
}
