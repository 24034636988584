import { css } from "@emotion/react";

export const HeaderStyle = {
  index: (language: string) => css`
    height: 40px;
    width: 100%;
    background-color: #06396a;
    background-image: url(${`/common/desktop/header/bg_topCtrl.jpg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-width: 1000px;
    z-index: 9;

    .changeLanguage {
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        object-fit: contain;
      }

      .popup {
        position: absolute !important;

        .item {
          cursor: pointer;
          transition: all 0.1s;
          &:hover {
            transform: scale(1.02);
            font-weight: 600;
          }
        }
        span {
          color: #000 !important;
        }
      }
    }
  `,
  content: css`
    width: 1000px;
    margin: 0 auto;
    height: 40px;
    display: flex;
    align-items: center;
    line-height: 1em;
  `,
  topTime: css`
    color: #a9c9e1;
    margin-right: 10px;
  `,
  topCtrl: (language: string) => css`
    display: flex;
    color: #3e85ad;
    margin-top: -1px;
    align-items: center;

    .btn_setFavor {
      width: 17px;
      height: 15px;
      cursor: pointer;
      margin-left: 10px;
      background-image: url(${`/common/desktop/header/btn_setFavor.png`});
    }
  `,
  loginArea: (language: string) => css`
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;

    div {
      float: left;
      line-height: 20px;
      cursor: pointer;
      color: #fff;

      &.btn_addMember {
        min-width: 95px;
        height: 20px;
        box-sizing: border-box;
        padding-left: 5px;
        margin-left: 10px;
        background-image: url(${`/common/desktop/header/btn_addMember.png`});
        text-decoration: none;
        padding-right: 26px;
        text-align: center;

        &:hover {
          background-position: bottom;
        }
      }

      &.btn_login {
        text-align: center;
        min-width: 85px;
        height: 20px;
        text-decoration: none;
        background-color: #00b2b9;
        border-radius: 3px;

        &:hover {
          background-color: #15d2d9;
        }
      }
    }
  `,
  memberArea: (language: string) => css`
    color: #fff;
    flex: 1;
    display: flex;
    justify-content: flex-end;

    ul {
      display: flex;
      align-items: center;

      li {
        padding: 0;
        margin: 0 4px;
      }

      .level5,
      .level4,
      .level3,
      .level2,
      .level1 {
        font-size: 15px;
        white-space: nowrap;
      }

      .level1 {
        color: #d4a34c;
      }

      .memberAC {
        cursor: pointer;
        position: relative;
        font-size: 15px;

        &:hover {
          .memberNick {
            display: block;
          }
        }

        .memberNick {
          display: none;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.5);
          color: #fff;
          z-index: 51;
          border-radius: 3px;
          padding: 5px;
          line-height: 17px;
          white-space: nowrap;
          top: 28px;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
        }
      }

      .memberFlow,
      .memberCtrl {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        &[aria-disabled="true"] {
          span {
            background-color: #f39800;
            opacity: 0.7;
            cursor: not-allowed;
            &:hover {
              background-color: #f39800;
            }
          }
        }
      }
      .btn_RoundTextX,
      .btn_RoundTextY,
      .btn_RoundTextZ {
        background-color: #1ebf8c;
        height: 26px;
        min-width: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        font-size: 14px;
        padding: 0 8px;
        box-sizing: border-box;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        position: relative;

        &:hover {
          background-color: #22cf98;
        }
      }
      .btn_RoundTextY,
      .btn_RoundTextZ {
        background-color: #f39800;
      }

      .memberCtrl {
        .btn_member,
        .btn_signout,
        .btn_personalMsg {
          width: 27px;
          height: 27px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24px auto;
        }

        .btn_member {
          background-image: url(${`/common/desktop/header/icon_member.png`});
        }

        .btn_personalMsg {
          background-image: url(${`/common/desktop/header/btn_personalMsg.png`});
        }

        .btn_signout {
          background-image: url(${`/common/desktop/header/icon_Signout.png`});
        }

        &:hover {
          .RoundText_prompt {
            display: block;
          }
        }
      }

      .RoundText_prompt {
        display: none;
        position: absolute;
        left: -22px;
        right: -22px;
        z-index: 51;
        top: 36px;
        white-space: nowrap;

        .RT_promptText {
          display: table;
          margin: 0 auto;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 3px;
          color: #fff;
          max-width: 250px;
          line-height: 17px;
          padding: 5px;
          position: relative;
          box-sizing: border-box;
        }
      }
    }
  `,

  tooltipDeposit: css`
    display: inline-flex;
    gap: 10px;
    color: #fff;
    align-items: center;
    font-size: 13px;
    white-space: pre-wrap;
    line-height: 17px;
    img {
      width: 15px;
    }
  `,
};
